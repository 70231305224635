import { memo, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import { CancelOrder, MultiplePlaceholder, LinkButton } from '@components/common';
import Images from '@assets/images';
import { OrderItem } from '@helpers/types/order';
import { CLIENT_ROUTES } from '@router/routes';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCommaSepratedValue, getFormattedDate } from '@helpers/utils';
import { cancelOrderSchema } from '@helpers/yup/add-order.schema';
import { OrderInfo } from '@helpers/types/order';
import AccessWrapper from '@authorization/access-wrapper';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';

interface OrderCardProps {
  readableOrderId: string;
  orderId: string;
  items: OrderItem[];
  customerName: string;
  createdBy?: any;
  currentState?: string;
  totalAmount?: string | null;
  orderInfo: OrderInfo;
  actions?: IActions;
  pendingTasks: string[];
}

interface ProgressBardState {
  labelList: any[];
  stepLabels: number[][];
  isCurrent: number;
}

const OrderCardInternal = (props: OrderCardProps) => {
  const {
    readableOrderId,
    orderId,
    customerName,
    createdBy,
    currentState,
    totalAmount,
    orderInfo,
    actions,
    pendingTasks = []
  } = props;

  const { currency } = orderInfo;
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [progressBarState, setProgressBarState] = useState<ProgressBardState>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });
  const { labelList, stepLabels, isCurrent } = progressBarState;

  const orderCancelForm = useForm<any>({
    resolver: yupResolver(cancelOrderSchema),
    defaultValues: {
      cancel_remarks: null
    },
    shouldUnregister: true
  });

  const itemName = useMemo(() => {
    const { items } = props;
    // const item = items.map((data) => data.supplier_product_grade?.product_grade?.product?.name);
    const item = items.map((data) => data.product_name);
    return <MultiplePlaceholder names={item} />;
  }, [props.items]);

  const supplier_names = useMemo(() => {
    const { items } = props;
    const name = items
      .filter((data) => data?.supplier_name !== null)
      .map((data) => data.supplier_name);
    return name;
  }, [props.items]);

  const supplierName = useMemo(() => {
    if (supplier_names && supplier_names.length > 0) {
      return <MultiplePlaceholder names={supplier_names} />;
    } else {
      return `-`;
    }
  }, [supplier_names]);

  const orderCreatedBy = useMemo(() => {
    if (createdBy?.length) {
      return createdBy;
    } else {
      return `-`;
    }
  }, [createdBy]);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      const labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? ''
        };
      });
      const isCurrentState = orderInfo?.is_order_completed
        ? Number.MAX_VALUE
        : orderInfo?.customer_state_activity_log.findIndex(
            (item: any) => item.is_current_state === true
          );
      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  const openCustomer = () => {
    window.open(`/customer/${orderInfo.customer?.customer_id}`, '_blank');
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <div>
          {cancelStatus.is_cancelled ? (
            <div className={css.cancelWrapper}>
              <div className={css.columnWrapper}>
                <Typography variant="p" className={css.cancelOrderTitle}>
                  This order has been cancelled by {cancelStatus.last_updated_by ?? 'Unknown User'}{' '}
                  on {cancelStatus.updated_at}
                </Typography>
                <Typography variant="p">
                  {cancelStatus.cancel_remarks ?? 'No Cancel Remarks'}
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`${css.columnWrapper} ${cancelStatus.is_cancelled ? css.disable : ``}`}>
            <Typography variant="span">#{readableOrderId}</Typography>
            <div className={css.headerWrapper}>
              <Typography variant="h3" onClick={openCustomer} className={css.hoverText}>
                {customerName}
              </Typography>
              <div className={css.sideWrapper}>
                {/* <NestedChip primaryLabel="Order Confirmed" secondaryLabel="Lifting Dates Set" /> */}
                <div className={css.buttonWrapper}>
                  <LinkButton
                    variant="outlined-secondary"
                    to={`/${CLIENT_ROUTES.order}/${orderId}`}
                    title="View Order"
                    eventTrackingName="ORDER_VIEW_CLICK">
                    View Order
                  </LinkButton>
                </div>
                <AccessWrapper show={actions?.delete || false}>
                  <div className={css.buttonWrapper}>
                    {cancelStatus.is_cancelled ? (
                      <></>
                    ) : (
                      <div
                        className="link-button-text-secondary"
                        onClick={() => setCancelOrderModal(true)}>
                        Cancel Order
                      </div>
                    )}
                  </div>
                </AccessWrapper>
              </div>
            </div>
          </div>
          {cancelStatus.is_cancelled ? (
            <></>
          ) : (
            <>
              <div className={css.rowWrapper}>
                <div className={css.orderDetailWrapper}>
                  <div className={css.itemWrapper}>
                    <IconNode
                      src={Images.personGrey}
                      alt="customer icon"
                      className={css.iconWrapper}
                    />
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Item(s)</Typography>
                      <Typography variant="body">{itemName}</Typography>
                    </div>
                  </div>
                  <div className={css.itemWrapper}>
                    <IconNode
                      src={Images.personGrey}
                      alt="supplier icon"
                      className={css.iconWrapper}
                    />
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Supplier</Typography>
                      {supplierName}
                    </div>
                  </div>
                  <div className={css.itemWrapper}>
                    <IconNode
                      src={Images.personGrey}
                      alt="supplier icon"
                      className={css.iconWrapper}
                    />
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Purchase Rep</Typography>
                      <MultiplePlaceholder names={orderInfo?.purchase_rep} />
                    </div>
                  </div>
                </div>
                <div className={css.orderDetailWrapper}>
                  <div className={css.itemWrapper}>
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Pending Tasks</Typography>
                      <Typography variant="body">
                        <MultiplePlaceholder names={pendingTasks} />
                      </Typography>
                    </div>
                  </div>
                  <div className={css.itemWrapper}>
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Created by</Typography>
                      <Typography variant="p">{orderCreatedBy}</Typography>
                    </div>
                  </div>
                  <div className={css.itemWrapper}>
                    <div className={css.detailWrapper}>
                      <Typography variant="ptext">Due Amount/Total amount</Typography>
                      <Typography variant="pdoc">
                        {currency} {getCommaSepratedValue(orderInfo?.amount_due)}/{currency}{' '}
                        {totalAmount}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.rowWrapper}>
                <StepProgressBar label={labelList} currentStep={isCurrent} />
              </div>
            </>
          )}
        </div>
      </div>
      <FormProvider {...orderCancelForm}>
        <CancelOrder
          open={cancelOrderModal}
          onClose={() => setCancelOrderModal(false)}
          orderId={orderId}
        />
      </FormProvider>
    </>
  );
};

export default memo(OrderCardInternal);
