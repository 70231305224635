import Typography from '@components/base/typography';
import { DataGrid } from '@components/common';
import DocumentRow from '@components/common/document-row';
import ListPlaceholder from '@components/common/list-placeholder';
import notify from '@helpers/toastify-helper';
import { getFormattedDate } from '@helpers/utils';
import { fetchInvoiceList } from '@services/finance.service';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import Button from '@components/base/button';
import DetailModal from './detail-modal';
import { TAG_LIMIT_VIEW } from '@helpers/constants';
import { Chip, ToolTip } from '@components/base';

const InvoicesTab = (props: any) => {
  const [invoicesState, setInvoicesState] = useState<any>({
    isLoading: false,
    tableData: null,
    detailModalOpen: false,
    detailModalData: null
  });

  const { isLoading, tableData, detailModalOpen, detailModalData } = invoicesState;
  const fetchInvoiceTableData = async () => {
    const response = await fetchInvoiceList();
    if (response?.success) {
      setInvoicesState({
        ...invoicesState,
        tableData: response?.data?.results ?? []
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  useEffect(() => {
    fetchInvoiceTableData();
  }, []);

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Invoice Details',
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: 'Document Type',
        accessor: 'document_type_display'
      },
      {
        Header: 'Document Name',
        accessor: 'name'
      },
      {
        Header: 'Amount',
        accessor: 'amount'
      },
      {
        Header: 'Tags',
        accessor: 'internal_tags',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <div className={css.chipGroup}>
              {value?.length ? (
                value?.length > TAG_LIMIT_VIEW ? (
                  <>
                    {value.slice(0, TAG_LIMIT_VIEW)?.map((tag: any) => (
                      <Chip key={tag} label={tag} className={css.chip} />
                    ))}
                    <ToolTip title={value.slice(TAG_LIMIT_VIEW).join(', ')} placement="right">
                      <div>
                        <Chip label={`+ ${value.length - TAG_LIMIT_VIEW}`} className={css.chip} />
                      </div>
                    </ToolTip>
                  </>
                ) : (
                  value?.map((tag: any) => <Chip key={tag} label={tag} className={css.chip} />)
                )
              ) : (
                <>-</>
              )}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <DocumentRow.View
                showEyeIconWhenDocumentMissing={true}
                document={value.document}
                title=""
              />
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'view',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Button
                variant="text"
                onClick={() =>
                  setInvoicesState((prevState: any) => ({
                    ...prevState,
                    detailModalOpen: true,
                    detailModalData: value
                  }))
                }>
                View Details
              </Button>
            </>
          );
        }
      }
    ];

    const row: Array<any> = tableData?.length
      ? tableData?.map((item: any) => ({
          invoice: item ?? null,
          document_type_display: item?.document_type_display ?? '-',
          name: item?.name ?? '-',
          amount: `${item.currency} ${item?.amount}`,
          internal_tags: item.internal_tags?.length ? item.internal_tags : '-',
          action: item ?? null,
          view: item ?? null
        }))
      : [];

    return [column, row];
  }, [tableData]);

  return (
    <div className={css.tableWrapper}>
      {rowData.length ? (
        <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
      ) : (
        <ListPlaceholder
          title="No Invoice available"
          supportingText="You have not created any invoice yet."
        />
      )}
      {detailModalOpen && (
        <DetailModal
          data={detailModalData}
          open={detailModalOpen}
          onClose={() =>
            setInvoicesState((prevState: any) => ({
              ...prevState,
              detailModalOpen: false,
              detailModalData: null
            }))
          }
        />
      )}
    </div>
  );
};

export default InvoicesTab;
