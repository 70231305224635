import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, Checkbox, CheckboxLabel, IconNode } from '@components/base';
import SelectLabel from '@components/common/select-label';
import Typography from '@components/base/typography';
import PaymentTerms from '@components/common/payment-terms';
import { InputDatePicker, TextField } from '@components/common';
import Images from '@assets/images';
import { CURRENCY, PAYMENT_STATUS } from '@helpers/constants';
import { IPaymentTermsFilter } from '@helpers/types/add-order';

interface IInvoiceUploadProps {
  onFormSubmit: (data: any) => void;
  onClose: () => void;
  editMode: string | null | undefined;
  piInvoiceDetails: any;
  isTaxInvoice: boolean;
  paymentTermsOptions: IPaymentTermsFilter[];
  handleBackClick: () => void;
  getPIDetails: () => void;
}

const InvoiceUpload = (props: IInvoiceUploadProps) => {
  const {
    onFormSubmit,
    onClose,
    editMode,
    piInvoiceDetails,
    isTaxInvoice = false,
    paymentTermsOptions,
    handleBackClick,
    getPIDetails
  } = props;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = useFormContext();

  const [invoiceState, setInvoiceState] = useState<{
    paymentInfo: Array<{
      amount_paid: number | null;
      payment_date: Date | null;
      payment_reference_number: string | null;
      amount_adjusted: number | null;
      is_checked: boolean;
    }> | null;
  }>({
    paymentInfo: null
  });

  const { paymentInfo } = invoiceState;

  const paymentInfoWatch = useWatch({
    name: 'pi_invoice_details.pi_invoice_payment_info',
    control
  });

  const amountPaidWatch = useWatch({
    name: 'amount_paid',
    control
  });

  useEffect(() => {
    isTaxInvoice && getPIDetails();
  }, []);

  const IS_PAYMENT_MODE = [
    {
      label: 'Full',
      value: true
    },
    {
      label: 'Partial',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ];

  const [filteredInvoicePaymentTermsOptions, setFilteredInvoicePaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);

  const invoicePaymentTermWatchField = useWatch({
    name: 'payment_terms',
    control
  });

  const piPaymentTermWatchField = useWatch({
    name: 'pi_invoice_details.pi_invoice_payment_terms',
    control
  });

  const currencyWatch = useWatch({
    name: 'invoice_currency',
    control
  });

  useEffect(() => {
    if (currencyWatch?.value !== 'INR') {
      setValue('invoice_gst', 0);
    } else {
      setValue('invoice_gst', '');
    }
  }, [currencyWatch]);

  useEffect(() => {
    const selectedPaymentTerms = invoicePaymentTermWatchField?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions?.filter((item: any) => {
      return !selectedPaymentTerms?.includes(item.payment_term);
    });
    setFilteredInvoicePaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [invoicePaymentTermWatchField]);

  const {
    fields: invoicePaymentTermFields,
    append: invoicePaymentTermAppend,
    remove: invoicePaymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const handleAddInvoicePaymentTermField = () => {
    invoicePaymentTermAppend({
      payment_term: null,
      percentage: '',
      days: ''
    });
  };

  const invoiceAmountWatch = useWatch({
    name: 'invoice_amount',
    control
  });

  const invoiceGSTWatch = useWatch({
    name: 'invoice_gst',
    control
  });

  useEffect(() => {
    const totalInvoiceAmount = invoiceAmountWatch ? Number(invoiceAmountWatch) : 0;
    const invoiceGST = invoiceGSTWatch ? Number(invoiceGSTWatch) : 0;
    const totalAmount = totalInvoiceAmount + invoiceGST;
    setValue('total_invoice_amount', totalAmount);
  }, [invoiceGSTWatch, invoiceAmountWatch]);

  const isPaymentModeWatch = useWatch({
    name: 'is_payment_mode',
    control: control
  });

  const multipleTaxInvoiceWatch = useWatch({
    name: 'multiple_tax_invoice',
    control: control
  });

  const onPISelection = (selectedOption: any) => {
    const selectedPaymentInfo = selectedOption?.payment_infos;
    const paidPaymentInfo = selectedPaymentInfo
      ?.filter((item: any) => item.payment_status === PAYMENT_STATUS.PAID)
      .map((item: any) => {
        return {
          id: item?.id,
          amount_paid: item?.payment_confirmation_details?.amount_paid,
          payment_date: item?.payment_confirmation_details?.payment_date,
          payment_reference_number: item?.payment_confirmation_details?.reference_number,
          amount_adjusted: null,
          is_checked: false
        };
      });
    setInvoiceState({
      ...invoiceState,
      paymentInfo: paidPaymentInfo
    });
    setValue('pi_invoice_details.pi_invoice_number', selectedOption?.invoice_number);
    setValue('pi_invoice_details.pi_invoice_date', new Date(selectedOption?.invoice_date));
    setValue('pi_invoice_details.pi_invoice_amount', selectedOption?.amount);
    setValue('pi_invoice_details.pi_invoice_payment_info', paidPaymentInfo);
    setValue(
      'pi_invoice_details.pi_invoice_payment_terms',
      selectedOption?.payment_terms
        .map((item: any) => {
          const paymentTerm = `${item.percentage}% ${item.payment_term}`;
          if (item.days) {
            return `${paymentTerm} in (${item.days} days)`;
          }
          return paymentTerm;
        })
        .join(', ')
    );
  };
  return (
    <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <section className={css.modalContent}>
        {isTaxInvoice && (
          <Controller
            name="is_payment_mode"
            control={control}
            render={({ field }) => (
              <SelectLabel label="Is Payment Mode" options={IS_PAYMENT_MODE} {...field} />
            )}
          />
        )}
        {isPaymentModeWatch?.value && (
          <div className={css.piInvoiceDetails}>
            <Controller
              name="pi_invoice_details.pi_document"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  label="PI Invoice Details"
                  placeholder="Select one"
                  isSearchable
                  options={piInvoiceDetails}
                  rootClassName={css.typeField}
                  getOptionLabel={(option: any) => `${option.invoice_number}`}
                  getOptionValue={(option: any) => option.invoice_info_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onChange={(e: any) => {
                    field.onChange(e);
                    onPISelection(e);
                  }}
                />
              )}
            />
            <div className={css.rowWrapper}>
              <Controller
                name="pi_invoice_details.pi_invoice_number"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="PI Invoice Number"
                    disabled
                    placeholder="PI Invoice Number"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={`pi_invoice_details.pi_invoice_date`}
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    label="PI Invoice Date"
                    placeholder="Select Date"
                    error={fieldState.invalid}
                    value={new Date(`${field?.value ?? ''}`) ?? ''}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldSpacing}
                    onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  />
                )}
              />
            </div>
            <Controller
              name="pi_invoice_details.pi_invoice_amount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required={true}
                  label="PI Invoice Amount"
                  disabled
                  placeholder="PI Invoice Amount"
                  error={fieldState.invalid}
                  rootClassName={css.typeField}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {piPaymentTermWatchField && (
              <div className={css.paymentTerms}>
                <Typography variant="inherit" className={css.paymentTermsLabel}>
                  Payment Terms
                </Typography>
                <Typography variant="body">{piPaymentTermWatchField}</Typography>
              </div>
            )}
          </div>
        )}
        {isTaxInvoice && (
          <Controller
            name="multiple_tax_invoice"
            control={control}
            render={({ field, fieldState }) => (
              <CheckboxLabel
                {...field}
                label="Multiple Tax Invoices"
                value={`${field.value}`}
                checked={field.value}
              />
            )}
          />
        )}
        <Controller
          name={`invoice_date`}
          control={control}
          render={({ field, fieldState }) => (
            <InputDatePicker
              {...field}
              required
              label="Invoice Date"
              placeholder="Select Date"
              error={fieldState.invalid}
              value={new Date(`${field?.value ?? ''}`) ?? ''}
              helperText={fieldState.error?.message}
              rootClassName={css.fieldSpacing}
              onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
            />
          )}
        />
        <div className={css.rowWrapper}>
          <Controller
            name="invoice_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice Number"
                placeholder="Invoice Number"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="invoice_currency"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                isClearable
                required
                isSearchable
                placeholder="Select Currency"
                label="Currency"
                options={CURRENCY}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                rootClassName={css.selectWrapper}
              />
            )}
          />
        </div>
        <div className={css.rowWrapper}>
          <Controller
            name="invoice_amount"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice Amount"
                placeholder="Invoice Amount"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="invoice_gst"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label="Invoice GST"
                disabled={currencyWatch?.value !== 'INR'}
                placeholder="Invoice GST"
                error={fieldState.invalid}
                rootClassName={css.typeField}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <Controller
          name="total_invoice_amount"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required={true}
              label="Total Invoice Amount"
              disabled
              placeholder="Total Invoice Amount"
              error={fieldState.invalid}
              rootClassName={css.typeField}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {isTaxInvoice && multipleTaxInvoiceWatch && (
          <>
            <div className={css.rowWrapper}>
              <Controller
                name="amount_paid"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label="Amount Paid"
                    placeholder="Amount Paid"
                    error={fieldState.invalid}
                    rootClassName={css.typeField}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
            {isPaymentModeWatch?.value && parseFloat(amountPaidWatch) > 0 && (
              <div className={css.piInvoiceDetails}>
                {paymentInfo &&
                  paymentInfo.map((item: any, index: number) => {
                    return (
                      <div key={index} className={css.rowWrapper}>
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].is_checked`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              className={css.checkbox}
                              value={`${field.value}`}
                              checked={field.value}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].payment_date`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputDatePicker
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled
                              label="Payment Date"
                              placeholder="Select Date"
                              error={fieldState.invalid}
                              value={new Date(`${field?.value ?? ''}`) ?? ''}
                              helperText={fieldState.error?.message}
                              rootClassName={css.fieldSpacing}
                              onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].payment_reference_number`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled
                              label="Reference Number"
                              placeholder="Payment Reference Number"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].amount_paid`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              label="Amount Paid"
                              disabled
                              placeholder="Amount Paid"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Controller
                          name={`pi_invoice_details.pi_invoice_payment_info[${index}].amount_adjusted`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              required={paymentInfoWatch?.[index]?.is_checked}
                              disabled={!paymentInfoWatch?.[index]?.is_checked}
                              label="Amount Adjusted"
                              placeholder="Amount Adjusted"
                              error={fieldState.invalid}
                              rootClassName={css.typeField}
                              endIcon={
                                fieldState.invalid && (
                                  <IconNode src={Images.alertError} alt="Error Icon" />
                                )
                              }
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        )}
        <PaymentTerms
          paymentTermFields={invoicePaymentTermFields}
          paymentTermRemove={invoicePaymentTermRemove}
          paymentTermsOptions={paymentTermsOptions}
          filteredPaymentTermsOptions={
            filteredInvoicePaymentTermsOptions?.length
              ? filteredInvoicePaymentTermsOptions
              : paymentTermsOptions
          }
          handleAddPaymentTermField={handleAddInvoicePaymentTermField}
        />
      </section>
      <section className={css.modalFooter}>
        <Button variant="outlined-secondary" onClick={handleBackClick} disabled={isSubmitting}>
          Back
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {editMode ? (isSubmitting ? 'Updating' : 'Update') : isSubmitting ? 'Saving' : 'Save'}
        </Button>
      </section>
    </form>
  );
};

export default InvoiceUpload;
