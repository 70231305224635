import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { Typography, Modal, IconNode, Button } from '@components/base';
import { useForm, FormProvider } from 'react-hook-form';
import Images from '@assets/images';
import { ISelect, ISelectBoolean } from '@helpers/types';
import {
  getAddDocFilterOptions,
  getPaymentTermsFilterOptions,
  getProformaInvoiceDetails,
  getSupplierFilterOptions,
  updateOrderOtherDoc,
  uploadOrderOtherDoc
} from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { addOtherDocument, invoiceSchema } from '@helpers/yup/add-document.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import DocumentUpload from './components/document-upload';
import InvoiceUpload from './components/invoice-upload';
import moment from 'moment';
import { paymentTermsValidation } from '@helpers/utils';
import { IPaymentTermsForm } from '@helpers/types/add-order';

const filter = createFilterOptions<any>();

interface formType {
  type: any;
  internal_tags: Array<any>;
  name: string | null;
  document_type: string;
  remarks: string | null;
  document_object: File | null;
  supplier: any;
  order_item: any;
  order_document_id: string | null;
  product_required: boolean;
  supplier_required: boolean;
  edit_mode: boolean;
  reason_for_update: string | null;
}

interface UploadDocumentProps {
  open: boolean;
  onClose: () => void;
  editMode?: string | null;
  availableDocumentType?: ISelect[];
  orderId: string;
  documentData?: any;
  getDocumentData?: (filters?: any) => Promise<void>;
}

interface IModalState {
  isLoading: boolean;
  activeStep: number;
  formNumber: number;
  isTaxInvoice: boolean;
  documentUploadData: any;
  paymentTermsOptions: any;
  piInvoiceDetails: any;
  isProductRequired: boolean;
  isSupplierRequired: boolean;
}

interface IPIPaymentInfo {
  id: number | null;
  payment_date: Date | null;
  payment_reference_number: string | null;
  amount_paid: number | null;
  amount_adjusted: number | null;
  is_checked: boolean;
}

interface IPIInvoice {
  pi_document: any;
  pi_invoice_number: string | null;
  pi_invoice_date: Date | null;
  pi_invoice_amount: number | null;
  pi_invoice_payment_terms: string;
  pi_invoice_payment_info: IPIPaymentInfo[] | null;
}

interface IInvoiceForm {
  is_payment_mode: ISelectBoolean | null;
  pi_invoice_details: IPIInvoice;
  invoice_number: string;
  invoice_date: Date | null;
  invoice_currency: ISelect | null;
  invoice_amount: number | null;
  invoice_gst: number | null;
  total_invoice_amount: string;
  amount_paid: string;
  amount_remaining: string;
  payment_terms: IPaymentTermsForm[];
  multiple_tax_invoice: boolean;
}

/**
 * Document Name is commented for now in types and in view, but functionality is kept because it might be brought back
 */
const UploadOrderDocument = (props: UploadDocumentProps) => {
  const { open, onClose, editMode, orderId, documentData, getDocumentData } = props;

  const formMethods = useForm<formType>({
    resolver: yupResolver(addOtherDocument),
    defaultValues: {
      type: null,
      internal_tags: [],
      name: null,
      document_type: 'OTHERS', //This will be deprecated
      remarks: null,
      document_object: null,
      supplier: null,
      order_item: [],
      order_document_id: null,
      product_required: false,
      supplier_required: false,
      edit_mode: false,
      reason_for_update: null
    },
    shouldUnregister: false
  });

  useEffect(() => {
    if (documentData) {
      formMethods.reset({
        type: {
          label: documentData?.document_type_display_value,
          value: documentData?.type
        },
        internal_tags: documentData?.internal_tags_fe_display,
        name: documentData?.display_name,
        remarks: documentData?.remarks,
        supplier: {
          supplier_id: documentData?.supplier,
          supplier_name: documentData?.supplier_name
        },
        order_item: documentData?.products?.map((item: any) => {
          return {
            order_item_name: item.product_name,
            order_item_id: item.product_id
          };
        }),
        order_document_id: documentData?.order_document_id,
        product_required: false,
        supplier_required: false,
        edit_mode: true,
        reason_for_update: null
      });
    }
  }, [documentData]);

  const invoiceForm = useForm<IInvoiceForm>({
    resolver: yupResolver(invoiceSchema),
    defaultValues: {
      is_payment_mode: null,
      pi_invoice_details: {
        pi_document: null,
        pi_invoice_number: null,
        pi_invoice_date: null,
        pi_invoice_amount: null,
        pi_invoice_payment_terms: '',
        pi_invoice_payment_info: null
      },
      invoice_number: '',
      invoice_date: null,
      invoice_currency: null,
      invoice_amount: null,
      invoice_gst: null,
      total_invoice_amount: '',
      amount_paid: '',
      amount_remaining: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      multiple_tax_invoice: false
    }
  });
  const { setValue: setInvoiceValue } = invoiceForm;

  const [uploadOrderDocumentState, setUploadOrderDocumentState] = useState({
    documentTypeFilterOptions: [],
    orderItemFilterOptions: [],
    supplierFilterOptions: [],
    tagsFilterOptions: [],
    defaultSupplierOptions: []
  });
  const [modalState, setModalState] = useState<IModalState>({
    isLoading: false,
    activeStep: 0,
    formNumber: 1,
    isTaxInvoice: false,
    documentUploadData: null,
    paymentTermsOptions: [],
    piInvoiceDetails: [],
    isProductRequired: false,
    isSupplierRequired: false
  });
  const {
    isLoading,
    activeStep,
    formNumber,
    isTaxInvoice,
    paymentTermsOptions,
    piInvoiceDetails,
    documentUploadData
  } = modalState;

  const handleDocTypeChange = (
    product: boolean,
    supplier: boolean,
    formNumber: number,
    isTaxInvoice: boolean
  ) => {
    setModalState((prevState: IModalState) => ({
      ...prevState,
      isProductRequired: product,
      isSupplierRequired: supplier,
      formNumber: formNumber,
      isTaxInvoice: isTaxInvoice
    }));
  };

  const getPaymentTermsOptions = async () => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setModalState((prevState: IModalState) => ({
        ...prevState,
        paymentTermsOptions: response.data?.payment_terms_options,
        isLoading: false
      }));
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    getFilterOptions();
    getPaymentTermsOptions();
    documentUploadData && getPIDetails();
  }, []);

  const getSupplierDetails = async (docType: number) => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getSupplierFilterOptions(orderId, docType);

    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState((prevState) => ({
        ...prevState,
        supplierFilterOptions: data,
        defaultSupplierOptions: data
      }));
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getFilterOptions = async () => {
    const response = await getAddDocFilterOptions(orderId);
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    if (response?.success) {
      const { data } = response;
      setUploadOrderDocumentState((prevState) => ({
        ...prevState,
        documentTypeFilterOptions: data.document_type_filter_options,
        orderItemFilterOptions: data.order_item_filter_options,
        tagsFilterOptions: data.internal_tags_filter_options
      }));
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else {
      notify({ message: 'Unable to fetch filter options', severity: 'error' });
    }
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
  };

  const documentUploadSubmit = (formData: any, data: any) => {
    if (data.document_object) {
      formData.append('document_object', data.document_object as Blob);
    } else if (!editMode) {
      notify({
        title: 'We have little problem',
        message: `Document is required`,
        severity: 'error'
      });
      return;
    }
    if (data?.type) {
      formData.append('type', data.type.value);
    }
    if (data?.name) {
      formData.append('name', data.name);
    }
    if (data?.supplier) {
      formData.append('supplier', data.supplier.supplier_id);
    }
    if (data?.remarks) {
      formData.append('remarks', data.remarks);
    }
    if (data?.order_item) {
      data?.order_item.forEach((item: any) => {
        formData.append('products', item.order_item_id);
      });
    }
    if (data?.internal_tags?.length) {
      data?.internal_tags.forEach((tag: any) => {
        formData.append('internal_tags', tag.value);
      });
    }
    formData.append('document_type', 'OTHERS');
    if (data?.reason_for_update) {
      formData.append('reason_for_update', data.reason_for_update);
    }

    if (!editMode) {
      formData.append('uploaded_outside_workflow', 'true');
    }
  };

  const onFormSubmit = async (data: any) => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const formData = new FormData();
    if (formNumber == 1) {
      documentUploadSubmit(formData, data);
      let response;
      if (data.order_document_id) {
        response = await updateOrderOtherDoc(orderId, formData, data.order_document_id);
      } else {
        response = await uploadOrderOtherDoc(orderId, formData);
      }
      if (response?.success) {
        notify({
          title: 'Success',
          message: `Document ${data.order_document_id ? `updated` : `uploaded`} successfully`,
          severity: 'success'
        });
        onClose();
        getDocumentData && getDocumentData();
      } else {
        notify({
          title: 'We have little problem',
          message:
            response.error ??
            `There was an error ${data.order_document_id ? `updating` : `uploading`} document.`,
          severity: 'error'
        });
      }
    } else {
      data?.pi_invoice_details?.pi_invoice_payment_info?.forEach((item: any) => {
        if (item.is_checked && item.amount_paid < item.amount_adjusted) {
          notify({
            message: `Amount adjusted should be less than amount paid`,
            severity: 'error'
          });
          setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
          return;
        }
      });

      if (
        data?.pi_invoice_details &&
        parseFloat(data.pi_invoice_details.pi_invoice_amount) <=
          parseFloat(data.total_invoice_amount)
      ) {
        notify({
          message: `Total invoice amount should be less than invoice amount`,
          severity: 'error'
        });
        setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
        return;
      }

      const adjustedPaymentDetails = data?.pi_invoice_details?.pi_invoice_payment_info
        ?.filter((Item: any) => Item.is_checked)
        .map((item: any) => {
          const obj: any = {};
          obj['parent_payment_info_id'] = item.id;
          obj['amount_to_pay'] = item.amount_adjusted;
          return obj;
        });

      const validateAmountAdjusted = adjustedPaymentDetails?.reduce((acc: number, item: any) => {
        return acc + parseFloat(item.amount_to_pay);
      }, 0);
      if (
        data?.pi_invoice_details?.pi_invoice_payment_info &&
        validateAmountAdjusted !== data?.amount_paid
      ) {
        notify({
          message: `Sum of adjusted amount should be equal to amount paid`,
          severity: 'error'
        });
        setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
        return;
      }

      documentUploadSubmit(formData, documentUploadData);
      paymentTermsValidation(data?.payment_terms);
      formData.append('invoice_number', data?.invoice_number);
      formData.append('amount', data?.invoice_amount);
      if (data?.invoice_date) {
        const date = `${moment(data?.invoice_date).format('YYYY-MM-DD')}`;
        formData.append('invoice_date', date);
      }
      if (data?.invoice_currency) {
        formData.append('currency', data?.invoice_currency.value);
      }
      if (data?.invoice_gst) {
        formData.append('gst_amount', data?.invoice_gst);
      }
      if (data?.payment_terms) {
        formData.append(
          'payment_terms',
          JSON.stringify(
            data?.payment_terms?.map((item: any) => {
              const obj: any = {};
              obj['payment_term'] = item.payment_term?.payment_term;
              obj['percentage'] = parseInt(item.percentage);
              if (item.days) {
                obj['days'] = parseInt(item.days);
              }
              return obj;
            })
          )
        );
      }
      if (data?.pi_invoice_details?.pi_document?.invoice_info_id) {
        formData.append('parent_invoice', data?.pi_invoice_details?.pi_document.invoice_info_id);
      }
      if (data?.multiple_tax_invoice) {
        formData.append('has_multiple_tax_invoice', data?.multiple_tax_invoice);
      }
      if (parseFloat(data?.amount_paid) > 0 && data?.pi_invoice_details?.pi_invoice_payment_info) {
        formData.append('adjusted_payment_details', JSON.stringify(adjustedPaymentDetails));
      }
      const response = await uploadOrderOtherDoc(orderId, formData);
      if (response?.success) {
        notify({
          title: 'Success',
          message: `Document uploaded successfully`,
          severity: 'success'
        });
        onClose();
        getDocumentData && getDocumentData();
      } else if (response?.error) {
        notify({
          title: 'We have little problem',
          message: response?.error ?? `There was an error uploading document.`,
          severity: 'error'
        });
      }
    }
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
  };

  const handleNext = (data: any) => {
    setModalState((prevState: IModalState) => ({
      ...prevState,
      activeStep: activeStep + 1,
      documentUploadData: {
        type: data.type,
        internal_tags: data.internal_tags,
        name: data.name,
        document_object: data.document_object,
        remarks: data.remarks,
        supplier: data.supplier,
        order_item: data.order_item,
        reason_for_update: data.reason_for_update
      }
    }));
    !isTaxInvoice
      ? setInvoiceValue('is_payment_mode', {
          label: 'No',
          value: false
        })
      : null;
  };

  const handleBackClick = () => {
    setModalState((prevState: IModalState) => ({
      ...prevState,
      activeStep: activeStep - 1
    }));
  };
  const getPIDetails = async () => {
    setModalState((prevState: IModalState) => ({ ...prevState, isLoading: true }));
    const response = await getProformaInvoiceDetails(
      orderId,
      documentUploadData?.type?.value,
      documentUploadData?.supplier?.supplier_id
    );
    if (response?.success) {
      setModalState((prevState: IModalState) => ({
        ...prevState,
        piInvoiceDetails: response.data?.results,
        isLoading: false
      }));
    } else if (response.error) {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch proforma invoice details'
      });
      setModalState((prevState: IModalState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleCancel = () => {
    onClose();
    formMethods.reset();
    invoiceForm.reset();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">{editMode ? `Edit` : `Upload`} Document</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        {activeStep === 0 && (
          <FormProvider {...formMethods}>
            <DocumentUpload
              onFormSubmit={onFormSubmit}
              editMode={editMode}
              onClose={onClose}
              getSupplierDetails={getSupplierDetails}
              uploadOrderDocumentState={uploadOrderDocumentState}
              formNumber={formNumber}
              handleNext={handleNext}
              modalState={modalState}
              handleDocTypeChange={handleDocTypeChange}
            />
          </FormProvider>
        )}
        {activeStep === 1 && (
          <FormProvider {...invoiceForm}>
            <InvoiceUpload
              onFormSubmit={onFormSubmit}
              onClose={onClose}
              editMode={editMode}
              paymentTermsOptions={paymentTermsOptions}
              handleBackClick={handleBackClick}
              piInvoiceDetails={piInvoiceDetails}
              isTaxInvoice={isTaxInvoice}
              getPIDetails={getPIDetails}
            />
          </FormProvider>
        )}
      </div>
    </Modal>
  );
};

export default UploadOrderDocument;
