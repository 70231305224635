import httpClient from './index.service';
import { SERVER_CONFIG } from '@helpers/constants';

export async function fetchPaymentList(params?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .map((key) => {
        if (params[key]) return `${key}=${params[key]}`;
      })
      .join('&');
  }
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.paymentList}?${paramQuery}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchInvoiceList(params?: any) {
  let paramQuery = '';
  if (params && Object.keys(params)?.length) {
    paramQuery = Object.keys(params)
      .map((key) => {
        if (params[key]) return `${key}=${params[key]}`;
      })
      .join('&');
  }
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.invoiceList}?${paramQuery}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function paymentAlert(id: string | null) {
  if (!id) return null;
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.payments}payment/${id}/${SERVER_CONFIG.paymentAlert}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}
