import Checkbox from '@components/base/checkbox/checkbox';
import { DataGrid, ListPlaceholder } from '@components/common';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { getFormattedDate } from '@helpers/utils';

const PaymentInitiated = (props: any) => {
  const { data } = props;
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);

  const onPaymentSelection = (e: any, value: any) => {
    setSelectedPayments((prevSelectedPayments) => {
      console.log('Previous selected payments:', prevSelectedPayments);
      if (e.target.checked) {
        // Check if the payment is already in the array
        const isAlreadySelected = prevSelectedPayments.some((payment) => payment.id === value.id);

        if (isAlreadySelected) {
          console.log('Payment already selected:', value);
          return prevSelectedPayments; // Return the current state without changes
        } else {
          console.log('Adding new payment:', value);
          return [...prevSelectedPayments, value];
        }
      } else {
        console.log('Removing payment:', value);
        return prevSelectedPayments.filter((item) => item.id !== value.id);
      }
    });
  };
  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Checkbox onChange={(e) => onPaymentSelection(e, value)} />
            </>
          );
        }
      },
      {
        Header: 'Order ID',
        accessor: 'readable_order_id'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Total Order Invoice Amount',
        accessor: 'total_invoice_amount'
      },
      {
        Header: 'Request Amount',
        accessor: 'total_invoice_payable'
      },
      {
        Header: 'Invoice Details',
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: 'Due Date',
        accessor: 'due_date'
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Button variant="text">Complete Payment</Button>
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          check_box: item,
          cart_id_display: item?.cart_id_display ?? '-',
          total_amount: `${item.currency} ${item?.total_amount}`,
          suppliers: item?.suppliers ?? '-',
          invoice_details: item?.invoice_details ?? null,
          created_at: getFormattedDate(item?.created_at ?? '')
        }))
      : [];

    return [column, row];
  }, [data]);

  return (
    <div className={css.tableWrapper}>
      {rowData.length ? (
        <>
          {selectedPayments?.length > 0 ? (
            <Button variant="text">
              Complete Payment for {selectedPayments?.length}{' '}
              {selectedPayments?.length == 1 ? `Payment` : `Payments`}
            </Button>
          ) : (
            <Button variant="text">Complete Payment for all Payments</Button>
          )}
          <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
        </>
      ) : (
        <ListPlaceholder
          title="No Payment Initiated"
          supportingText="You have not initiated any payment yet."
        />
      )}
    </div>
  );
};

export default PaymentInitiated;
