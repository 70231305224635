import { Chip, Modal } from '@components/base';
import css from './index.module.scss';
import Typography from '@components/base/typography';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';
import Button from '@components/base/button';
import { getFormattedDate, getReadablePaymentTerm, sanitizeValue } from '@helpers/utils';
import DocumentRow from '@components/common/document-row';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DataGrid, MultiplePlaceholder } from '@components/common';

function sortPaymentsByStatus(payments: any) {
  const statusOrder: any = {
    paid: 0,
    initiated: 1,
    requested: 2
  };

  return payments.sort((a: any, b: any) => {
    const statusA: any = a.payment_status.toLowerCase();
    const statusB: any = b.payment_status.toLowerCase();

    return statusOrder[statusA] - statusOrder[statusB];
  });
}

const DetailModal = (props: any) => {
  const { open, onClose, data } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Invoice Details of {data?.invoice_number}</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <section className={css.modalContent}>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Invoice Date</Typography>
                <Typography variant="p">{getFormattedDate(data?.invoice_date) ?? '-'}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Invoice Number</Typography>
                <Typography variant="p">{sanitizeValue(data?.invoice_number)}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Uploaded On</Typography>
                <Typography variant="p">{getFormattedDate(data?.created_at) ?? '-'}</Typography>
              </div>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Additional Charges or Discounts</Typography>
                <Typography variant="p">
                  {data?.currency} {sanitizeValue(data?.additional_charges_or_discounts)}
                </Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">TCS Amount</Typography>
                <Typography variant="p">
                  {data?.currency} {sanitizeValue(data?.tcs_amount)}
                </Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">TDS Amount</Typography>
                <Typography variant="p">
                  {data?.currency} {sanitizeValue(data?.tds_amount)}
                </Typography>
              </div>
            </div>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Amount</Typography>
                <Typography variant="p">
                  {data?.currency} {sanitizeValue(data?.amount)}
                </Typography>
              </div>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Document Name</Typography>
                <Typography variant="p">{sanitizeValue(data?.name)}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Document Type</Typography>
                <Typography variant="p">{sanitizeValue(data?.document_type_display)}</Typography>
              </div>
              <DocumentRow.View
                showEyeIconWhenDocumentMissing={true}
                document={data?.document}
                title=""
              />
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Tags</Typography>
                <div className={css.chipGroup}>
                  {data?.internal_tags?.length ? (
                    <div className={css.chipWrapper}>
                      {data?.internal_tags?.map((tag: any) => (
                        <Chip key={tag} label={tag} className={css.chip} />
                      ))}
                    </div>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.rowWrapper}>
              <div className={css.dataWrapper}>
                <Typography variant="label">Payment Term</Typography>
                <Typography variant="p">{data?.payment_terms_display}</Typography>
              </div>
              <div className={css.dataWrapper}>
                <Typography variant="label">Multiple Tax Invoices?</Typography>
                <Typography variant="p">{data?.has_multiple_tax_invoice ? 'Yes' : 'No'}</Typography>
              </div>

              <div className={css.dataWrapper}>
                <Typography variant="label">Products</Typography>
                <Typography variant="p">
                  {
                    <MultiplePlaceholder
                      names={data?.products?.map((item: any) => item.product_name)}
                    />
                  }
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <PaymentTable data={data?.payment_infos} />
          </div>
        </section>
      </div>
    </Modal>
  );
};

const PaymentTable = (props: any) => {
  const { data } = props;
  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Payment ID',
        accessor: 'payment_id_display'
      },
      {
        Header: 'Amount to pay',
        accessor: 'amount_to_pay'
      },
      {
        Header: 'Due Date',
        accessor: 'due_date'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier_name'
      },

      {
        Header: 'Payment Status',
        accessor: 'payment_status',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Chip label={value?.value} variant={value?.chipVariant} />
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          payment_id_display: sanitizeValue(item?.payment_id_display),
          amount_to_pay: `${item.currency} ${item?.amount_to_pay}`,
          due_date: sanitizeValue(getFormattedDate(item?.due_date)),
          supplier_name: sanitizeValue(item?.supplier_name),
          payment_status: {
            value: sanitizeValue(item?.payment_status),
            chipVariant:
              item?.payment_status === 'paid'
                ? 'filled-green'
                : item?.payment_stauts === 'initiated'
                ? 'filled-blue'
                : 'filled-yellow'
          }
        }))
      : [];

    return [column, row];
  }, [data]);
  return (
    <>
      <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
    </>
  );
};

export default DetailModal;
