import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { Tab, Tabs } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Chip, DatePicker, Typography } from '@components/base';
import PaymentRequested from './components/paymentRequested';
import PaymentIntitated from './components/paymentInitiated';
import PaymentDone from './components/paymentDone';
import { fetchInvoiceList, fetchPaymentList } from '@services/finance.service';
import notify from '@helpers/toastify-helper';
import { CellProps } from 'react-table';
import { getFormattedDate } from '@helpers/utils';
import {
  AutoComplete,
  DataGrid,
  DocumentRow,
  InputDatePicker,
  SelectLabel
} from '@components/common';
import ListPlaceholder from '@components/common/list-placeholder';
import InvoicesTab from '@components/common/finance/invoice-table';
import { getSupplierNames } from '@services/supplier.service';
import { getReadableOrderIdList } from '@services/order.service';
import { getToken } from '@helpers/auth-helper';
import moment from 'moment';

const MAIN_STATE_MAPPING = [
  {
    key: 'payments',
    value: 0,
    default: true
  },
  {
    key: 'invoices',
    value: 1
  }
];

const PAYMENT_TAB_STATE_MAPPING = [
  {
    key: 'requested',
    value: 0,
    default: true
  },
  {
    key: 'initiated',
    value: 1
  },
  {
    key: 'paid',
    value: 2
  }
];

const groupPaymentsByCart = (payments: any) => {
  if (!payments) return null;
  const groupedPayments = payments.reduce((acc: any, payment: any) => {
    const cartId = payment?.payment_cart?.cart_id_display;

    if (!acc[cartId]) {
      acc[cartId] = {
        cart_id_display: cartId,
        created_at: payment?.payment_cart?.created_at,
        total_amount: 0,
        suppliers: new Set(),
        invoice_details: []
      };
    }

    acc[cartId].total_amount += parseFloat(payment.total_payable_amount);
    acc[cartId].suppliers.add(payment.supplier_name);
    acc[cartId].invoice_details.push({
      invoice_number: payment.invoice_number,
      invoice_info: payment.invoice_info,
      invoice_document: payment.invoice_document,
      invoice_date: payment.invoice_date
    });

    return acc;
  }, {});

  // Convert the grouped payments object to an array and format the data
  return Object.values(groupedPayments).map((group: any) => ({
    ...group,
    total_amount: group.total_amount.toFixed(2),
    suppliers: Array.from(group.suppliers).join(', ')
  }));
};

const Home = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabParam = searchParams.get('activeTab');
  const defaultTab = useMemo(
    () => MAIN_STATE_MAPPING.find((item: any) => item.default) ?? MAIN_STATE_MAPPING[0],
    []
  );
  const defaultSubTab = useMemo(
    () =>
      PAYMENT_TAB_STATE_MAPPING.find((item: any) => item.default) ?? PAYMENT_TAB_STATE_MAPPING[0],
    []
  );

  const getValueByKey = (key: string) => {
    const state = MAIN_STATE_MAPPING.find((item: any) => item.key === key);
    return state ? state?.value : defaultTab.value;
  };

  const initialOrderState = useMemo(() => {
    return getValueByKey(activeTabParam || defaultTab.key);
  }, [activeTabParam]);

  const [homeState, setHomeState] = useState({
    isLoading: false,
    activeTab: initialOrderState
  });

  const { isLoading, activeTab } = homeState;

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    const queryKey = MAIN_STATE_MAPPING.find((state) => state.value === newValue);
    setHomeState({
      ...homeState,
      activeTab: newValue
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeTab: queryKey?.key ?? defaultTab.key
    });
  };
  return (
    <main className={css.mainWrapper}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Payments" value={0} />
        <Tab label="Invoices" value={1} />
      </Tabs>

      {activeTab === 0 && <PaymentsTab />}
      {activeTab === 1 && <InvoicesTab />}
    </main>
  );
};

const PaymentsTab = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const is_finance_team_member = useMemo(() => getToken('permissions')?.is_finance_team_member,[])

  const activeSubTabParam = searchParams.get('activeSubTab');
  const defaultSubTab = useMemo(
    () =>
      PAYMENT_TAB_STATE_MAPPING.find((item: any) => item.default) ?? PAYMENT_TAB_STATE_MAPPING[0],
    []
  );
  const getValueBySubKey = (key: string) => {
    const state = PAYMENT_TAB_STATE_MAPPING.find((item: any) => item.key === key);
    return state ? state?.value : defaultSubTab.value;
  };
  const initialSubTabState = useMemo(() => {
    return getValueBySubKey(activeSubTabParam || defaultSubTab.key);
  }, [activeSubTabParam]);

  const [paymentsTabState, setPaymentsTabState] = useState({
    isLoading: false,
    activeSubTab: initialSubTabState,
    tableData: null
  });

  const [filterStates, setFilterStates] = useState<any>({
    supplierList: [],
    orderList: [],
    selectedSupplier: null,
    selectedOrder: null,
    selectedStartDate: null,
    selectedEndDate: null,
    currentPageNumber: 1
  });

  const { isLoading, activeSubTab, tableData } = paymentsTabState;
  const {
    supplierList,
    orderList,
    selectedSupplier,
    selectedOrder,
    selectedStartDate,
    selectedEndDate,
    currentPageNumber
  } = filterStates;

  const handleChipClick = (newValue: number) => {
    const queryKey = PAYMENT_TAB_STATE_MAPPING.find((state) => state.value === newValue);
    setPaymentsTabState({
      ...paymentsTabState,
      activeSubTab: newValue
    });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      activeSubTab: queryKey?.key ?? defaultSubTab.key
    });
  };

  useEffect(() => {
    fetchPaymentTableData();
  }, [
    activeSubTab,
    selectedSupplier,
    selectedOrder,
    selectedStartDate,
    selectedEndDate,
    currentPageNumber
  ]);

  useEffect(() => {
    fetchSupplierNames();
    fetchOrderList();
  }, []);

  const fetchPaymentTableData = async () => {
    const response = await fetchPaymentList({
      status: PAYMENT_TAB_STATE_MAPPING?.find((item) => item.value === activeSubTab)?.key,
      supplier: selectedSupplier?.supplier_id,
      order: selectedOrder?.order_id,
      lower_bound_date: selectedStartDate
        ? `${moment(selectedStartDate).format('YYYY-MM-DD')}`
        : null,
      upper_bound_date: selectedEndDate ? `${moment(selectedEndDate).format('YYYY-MM-DD')}` : null
    });
    if (response?.success) {
      setPaymentsTabState({
        ...paymentsTabState,
        tableData: response?.data?.results ?? null
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const fetchSupplierNames = async () => {
    const response = await getSupplierNames();
    if (response?.success) {
      setFilterStates((prevState: any) => ({
        ...prevState,
        supplierList: response?.data ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const fetchOrderList = async (event?: any, param?: any) => {
    const response = await getReadableOrderIdList(param);
    if (response?.success) {
      setFilterStates((prevState: any) => ({
        ...prevState,
        orderList: response?.data?.results ?? []
      }));
    } else {
      notify({
        severity: 'error',
        message: response?.message
      });
    }
  };

  const changePage = (newPage: number) => {
    setFilterStates((prevState: any) => ({
      ...prevState,
      currentPageNumber: newPage
    }));
  };

  return (
    <div className={css.paymentsTabWrapper}>
      <div className={css.chipWrapper}>
        <Chip
          label="Payment Requested"
          variant={activeSubTab == 0 ? `filled` : `outlined`}
          onClick={() => handleChipClick(0)}
        />
        <Chip
          label="Payment Initiated"
          variant={activeSubTab == 1 ? `filled` : `outlined`}
          onClick={() => handleChipClick(1)}
        />
        <Chip
          label="Payment Completed"
          variant={activeSubTab == 2 ? `filled` : `outlined`}
          onClick={() => handleChipClick(2)}
        />
      </div>
      {activeSubTab != 1 && (
        <div className={css.filterWrapper}>
          <SelectLabel
            rootClassName={css.selectLabel}
            label="Supplier"
            placeholder="Select Supplier"
            isSearchable
            value={selectedSupplier}
            options={supplierList}
            isClearable
            getOptionLabel={(options: any) => options?.supplier_name}
            getOptionValue={(options: any) => options?.supplier_id}
            onChange={(value: any) => {
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedSupplier: value
              }));
            }}
          />
          <AutoComplete
            label="Order ID"
            options={orderList}
            onInputChange={(event: any, param: string) => fetchOrderList(event, param)}
            onInputSelection={(event: any, param: string) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedOrder: param
              }))
            }
            keyOption="readable_order_id"
            getOptionValue={(option: any) => option.readable_order_id}
            placeholder="Search Order"
            width="350px"
          />
          <InputDatePicker
            required
            label="Start Date"
            value={selectedStartDate}
            onSelect={(day: Date | undefined) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedStartDate: day
              }))
            }
            rootClassName={css.fieldSpacing}
          />
          <InputDatePicker
            required
            label="End Date"
            value={selectedEndDate}
            onSelect={(day: Date | undefined) =>
              setFilterStates((prevState: any) => ({
                ...prevState,
                selectedEndDate: day
              }))
            }
            rootClassName={css.fieldSpacing}
          />
        </div>
      )}

      {activeSubTab === 0 && (
        <PaymentRequested
          data={tableData}
          currentPageNumber={currentPageNumber}
          changePage={changePage}
        />
      )}
      {activeSubTab === 1 && <PaymentIntitated data={groupPaymentsByCart(tableData)} />}
      {activeSubTab === 2 && <PaymentDone data={tableData} />}
    </div>
  );
};

// const InvoicesTab = (props: any) => {
//   const [invoicesState, setInvoicesState] = useState<any>({
//     isLoading: false,
//     tableData: null
//   });

//   const { isLoading, tableData } = invoicesState;
//   const fetchInvoiceTableData = async () => {
//     const response = await fetchInvoiceList();
//     if (response?.success) {
//       setInvoicesState({
//         ...invoicesState,
//         tableData: response?.data ?? []
//       });
//     } else {
//       notify({
//         severity: 'error',
//         message: response?.message
//       });
//     }
//   };

//   useEffect(() => {
//     fetchInvoiceTableData();
//   }, []);

//   const [columnData, rowData] = useMemo(() => {
//     const column: Array<any> = [
//       {
//         Header: 'Invoice Details',
//         accessor: 'invoice',
//         Cell: (props: CellProps<any>) => {
//           const { value } = props;
//           return (
//             <>
//               <Typography variant="p">{value?.invoice_number}</Typography>
//               <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
//             </>
//           );
//         }
//       },
//       {
//         Header: 'Document Type',
//         accessor: 'document_type_display'
//       },
//       {
//         Header: 'Document Name',
//         accessor: 'name'
//       },
//       {
//         Header: 'Amount',
//         accessor: 'amount'
//       },
//       {
//         Header: 'Tags',
//         accessor: 'tags'
//       },
//       {
//         Header: '',
//         accessor: 'action',
//         Cell: (props: CellProps<any>) => {
//           const { value } = props;
//           return (
//             <>
//               <DocumentRow.View
//                 showEyeIconWhenDocumentMissing={true}
//                 document={value.document}
//                 title=""
//               />
//             </>
//           );
//         }
//       }
//     ];

//     const row: Array<any> = tableData?.length
//       ? tableData?.map((item: any) => ({
//           invoice: item ?? null,
//           document_type_display: item?.document_type_display ?? '-',
//           name: item?.name ?? '-',
//           amount: `${item.currency} ${item?.amount}`,
//           tags: item.internal_tags?.length ? item.internal_tags.join(', ') : '-',
//           action: item ?? null
//         }))
//       : [];

//     return [column, row];
//   }, [tableData]);

//   return (
//     <div className={css.tableWrapper}>
//       {rowData.length ? (
//         <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
//       ) : (
//         <ListPlaceholder
//           title="No Invoice available"
//           supportingText="You have not created any invoice yet."
//         />
//       )}
//     </div>
//   );
// };

export default Home;
