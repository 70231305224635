/* eslint-disable @typescript-eslint/no-var-requires */
const Images: Record<string, string> = {
  loginCover: require('./login-cover.svg').default,
  elchemyLogo: require('./elchemy-logo.svg').default,
  googleLogo: require('./google-logo.svg').default,
  backArrow: require('./back-arrow.svg').default,
  alertError: require('./alert-error.svg').default,
  alertSuccess: require('./alert-success.svg').default,
  alertInfo: require('./alert-info.svg').default,
  alertWarning: require('./alert-warning.svg').default,
  crossRed: require('./cross-red.svg').default,
  crossGreen: require('./cross-green.svg').default,
  crossBlue: require('./cross-blue.svg').default,
  crossYellow: require('./cross-yellow.svg').default,
  crossBlack: require('./cross-black.svg').default,
  plusRed: require('./plus-red.svg').default,
  deleteRed: require('./delete-red.svg').default,
  editRed: require('./edit-red.svg').default,
  eyeRed: require('./eye-icon-red.svg').default,
  rightTickGreen: require('./right-tick-green.svg').default,
  editGrey: require('./edit-grey.svg').default,
  editDark: require('./edit-dark.svg').default,
  notificationBell: require('./notification-bell.svg').default,
  filter: require('./filter.svg').default,
  search: require('./search.svg').default,
  telephone: require('./telephone.svg').default,
  locationPin: require('./location-pin.svg').default,
  upload: require('./upload-grey.svg').default,
  delete: require('./delete-grey.svg').default,
  deleteDark: require('./delete-dark.svg').default,
  infoGrey: require('./info-grey.svg').default,
  personGrey: require('./person-grey.svg').default,
  productGrey: require('./product-grey.svg').default,
  hazardGrey: require('./hazard-grey.svg').default,
  downloadGrey: require('./download-grey.svg').default,
  downloadLightGrey: require('./download-light-grey.svg').default,
  documentInfo: require('./document-info.svg').default,
  alertSuccessFaint: require('./alert-success-faint.svg').default,
  illustrationEdit: require('./illustration-edit.svg').default,
  illustration: require('./illustration.svg').default,
  illustrationGreen: require('./illustration-green.svg').default,
  settingGrey: require('./setting-grey.svg').default,
  uploadRed: require('./upload-red.svg').default,
  arrowDown: require('./arrow-down-grey.svg').default,
  calendarGrey: require('./calendar-grey.svg').default,
  dotGrey: require('./dot-grey.svg').default,
  assignmentIcon: require('./assignment-icon.svg').default,
  defaultProfile: require('./default-profile.svg').default,
  downloadDark: require('./download-dark.svg').default,
  sales: require('./sales.svg').default,
  sales2: require('./sales2.svg').default,
  pdf: require('./pdf.svg').default,
  word: require('./word.png'),
  progressBlue: require('./progress-bar-blue-dot.svg').default,
  progressGray: require('./progress-bar-gray-dot.svg').default,
  zipFileSvg: require('./zipfile.svg').default,
  invoice: require('./invoice.svg').default,
  pageNotFound: require('./404.png'),
  downloadRed: require('./download-red.svg').default,
  unauthorised: require('./401.png'),
  disclaimer: require('./disclaimer.svg').default,
  alertGrey: require('./alert-grey.svg').default,
  colouredPdf: require('./pdf-coloured.svg').default,
  colouredWord: require('./word.svg').default,
  threeDots: require('./three-dots.svg').default,
  settings: require('./setting.svg').default,
  greyEye: require('./eye-icon-grey.svg').default,
  bottle: require('./bottle-droplet.svg').default,
  send: require('./send-icon.svg').default,
  customer: require('./person-solid.svg').default,
  parties: require('./parties-icon.svg').default,
  orderDetails: require('./orderDetails-icon.svg').default,
  itemDetails: require('./itemDetails-icon.svg').default,
  packagingDetails: require('./packagingDetails-icon.svg').default,
  shipmentDetails: require('./shipmentDetails-icon.svg').default,
  blDetails: require('./blDetails-icon.svg').default,
  paymentDetails: require('./paymentDetails-icon.svg').default,
  remarks: require('./remarks-icon.svg').default,
  estimates: require('./estimatesIcon.svg').default,
  girlbgsvg: require('./girlBg.svg').default,
  userGroup: require('./user-group.svg').default,
  userGroupGrey: require('./user-group-grey.svg').default,
  sendPending: require('./send-pending.svg').default,
  fileInvoice: require('./fileInvoice.svg').default,
  close: require('./close.svg').default,
  unshare: require('./unshare.svg').default,
  unshareGrey: require('./unshare-grey.svg').default,
  progressBarGIF: require('./progress-bar-animation.gif'),
  enquiryIllustration: require('./enquiryIllustration.svg').default,
  menu: require('./menu.svg').default,
  forwardRight: require('./forward-right.svg').default,
  previousLeft: require('./previous-left.svg').default,
  listingPlaceholder: require('./listing-placeholder.png'),
  generateLabel: require('./generate-label.svg').default,
  colouredPowerpoint: require('./coloured-powerpoint.svg').default,
  powerpoint: require('./powerpoint.svg').default,
  sort: require('./sort.svg').default,
};
export default Images;
