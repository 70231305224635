import uniqueId from 'lodash/uniqueId';

export const SERVER_CONFIG = {
  listUsers: 'auth/users/',
  login: 'auth/login/',
  loginGoogle: 'auth/login/google/',
  register: 'auth/register/',
  resetEmail: 'auth/request-reset-email/',
  refreshToken: 'auth/token/refresh/',
  passwordReset: 'auth/password-reset-complete/',
  listSuppliers: 'supplier/suppliers/',
  getSupplier: 'supplier/supplier/',
  addSupplier: 'supplier/suppliers/',
  updateSupplier: 'supplier/supplier/',
  createSupplierDocument: 'supplier/documents/',
  updateDocument: 'supplier/document/',
  listSupplierProducts: 'supplier/suppliers/products/',
  listCustomers: 'customer/customers/',
  createCustomer: 'customer/customers/',
  updateCustomer: 'customer/',
  fetchCustomer: 'customer/',
  createCustomerDocument: 'customer/documents/',
  updateCustomerDocument: 'customer/document/',
  deleteCustomerDocument: 'customer/document/',
  listProducts: 'product/products/',
  createProduct: 'product/products/',
  fetchProduct: 'product/product/',
  updateProductInfo: 'product/product/',
  updateProductGrade: 'product/product/grade/',
  deleteGradeDocument: 'product/product/grade/document/',
  listEntity: 'order/self-entities/',
  listEntityBankInfo: 'order/entity-bank-details/',
  uploadEcgc: 'customer/',
  listOrder: 'order/orders/',
  createOrder: 'order/orders/',
  fetchOrderInfo: 'order/order/',
  fetchOrderInfoFinance: 'order/order/',
  updateOrderInfo: 'order/order/',
  createBillOfExchange: 'generate-boe/',
  createCRLDetails: 'generate-crl',
  createPO: '/document_generation/purchase_order/create/',
  getPO: 'order',
  order: 'order',
  approveOrder: 'approve-order',
  rejectOrder: 'reject-order',
  createCI: 'generate-custom-invoice/',
  postPerformaInvoice: 'generate-proforma-invoice/proforma_invoice/create/',
  uploadVesselDetails: 'order/',
  assignPersonnel: 'assign-personnel',
  getAllTasks: 'order/tasks',
  rectifyOrder: 'rectify-order',
  liftingDate: 'material-lifting-date/',
  getReviewById: 'pi/review/',
  getPOReview: 'po',
  approvePo: 'approve-purchase-order',
  rectifyPo: 'rectify-purchase-order',
  rejectPo: 'reject-purchase-order',
  approvePi: 'approve-proforma-invoice/',
  rejectPi: 'reject-proforma-invoice/',
  rectifyPi: 'rectify-proforma-invoice/',
  orderDocument: 'order-documents/',
  orderItemDocument: 'order-item-documents/',
  preShipment: 'pre-shipment-documents/',
  postShipment: 'post-shipment-documents/',
  exportClearance: 'export-clearance-documents/',
  crl: 'crl',
  uploadPaymentProof: 'upload-order-payment-proof/',
  taskList: 'order/tasks',
  localTransportation: 'local-transportation-detail',
  materialLiftingConfirmation: 'material-lifting-confirmation',
  courrierDetail: 'add-courrier-details/',
  activityLog: 'get-activity-log/',
  orderDocumentList: 'order-documents/v1',
  amountInWords: 'api/utils/amount_in_words',
  fetchCountryList: 'api/countries/filter-options',
  fetchStateList: 'api/states/filter-options',
  fetchCityList: 'api/cities/filter-options',
  getOrderDocZip: 'documents/zip/',
  getOrderImages: 'order-image/',
  formFilterOptions: {
    orderDocUpload: 'order-document/filter-options/',
    paymentTerms: 'api/payment-terms/filter-options/',
    packaging: 'packaging/filter-options',
    shippingLines: 'api/shipping-lines/filter-options/',
    emailCompose: 'emails/filter-options/',
    history: 'emails/',
    paymentTermsSuffix: 'api/payment-terms-suffix/filter-options/',
    supplier: 'supplier/filter-options/',
    index: 'filter-options',
    supplierNames: 'supplier/name/filter-options/'
  },
  addOrderOtherDoc: 'order-document',
  getOrderOtherDoc: 'order-files/',
  getPendingTasks: 'ongoing-tasks-info/',
  shipmentTracking: 'shipment/tracking-data',
  addContainerData: 'add-container-details',
  addBLDetails: 'add-bl-details',
  generateCommercialInvoice: 'generate-commercial-invoice',
  generateCOA: 'generate-coa',
  proofOfExport: 'add-proof-of-export',
  generateCOO: 'generate-coo',
  packageDetailsTasks: {
    verify: 'verify-packaging-details',
    approve: 'approve-packaging-details',
    rectify: 'rectify-packaging-details'
  },
  orderItems: 'order-item',
  addBatchNumber: 'add-batch-number',
  generatePreShipmentPackingList: 'generate-pre-shipment-packing-list',
  generatePostShipmentPackingList: 'generate-post-shipment-packing-list',
  generateShippingInstruction: 'generate-si',
  fetchPortList: 'api/ports/filter-options/',
  addTaskEstimates: 'add-task-estimates/',
  uploadBlDraft: 'upload-draft-bl',
  addOperationCosts: 'add-operational-costs',
  templateDetails: 'email/template',
  sendEmail: 'email/send',
  updateTaskEstimates: 'update-task-estimates/',
  pendingActionsInOrder: 'pending-actions-for-user/',
  uploadLCDetails: 'order/',
  resolveInconsistency: 'resolve-inconsistency',
  shareDocumentsDirectly: 'share-with-customer',
  getDocumentLink: 'api/documents/pre-signed-url/',
  getOrderPoc: 'poc',
  deleteStuffingPhotos: 'stuffing-images/delete/',
  unshareDocument: 'document/',
  unshareDocumentDirectly: 'unshare',
  download: 'download',
  readableOrderIdList: 'order_readable_id_list/',
  sendOtp: 'auth/send-email-otp/',
  logout: 'auth/logout/',
  adjacent: 'adjacent',
  rfqs: 'rfqs/',
  rfq: 'rfq',
  downloadZip: 'download-zip',
  getDocThatWillBeModified: 'modifications',
  editOrder: 'modify-data',
  checkOrderEditFields: 'modify/fields',
  taskType: 'task-types/',
  createNotes: 'note/',
  getNotes: 'notes/',
  delete: 'delete',
  documentGeneration: 'document_generation/',
  label: 'label/',
  generateLabels: 'generate-labels/v1',
  getPOInitialData: 'get_po_initial_data/',
  supplier: 'suppliers',
  invoices: 'invoices/',
  payments: 'payments/',
  paymentList: 'payments/payables/',
  invoiceList: 'payments/invoices/',
  paymentAlert: 'remind'
};

export const CUSTOMER_SERVER_CONFIG = {
  main: 'customer/dashboard',
  listOrder: 'orders',
  order: 'order',
  activityLog: 'activity-logs',
  shipmentTracking: 'shipment-tracking',
  getOrderImages: 'stuffing-images',
  documents: 'documents',
  getOrderPoc: 'pocs',
  sendOtp: 'auth/send-email-otp/'
};

export const userRoles = {
  admin: 1,
  internal: 2,
  external: 3
};

export const typographyVariantsMapping: Record<string, string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subheading1: 'h6',
  subheading2: 'h6',
  body: 'p',
  span: 'span',
  p: 'p',
  ptext: 'span',
  pdoc: 'span',
  label: 'label',
  inherit: 'span'
};

export const alertIconMapping: Record<string, string> = {
  error: 'alertError',
  info: 'alertInfo',
  success: 'alertSuccess',
  warning: 'alertWarning'
};

export const crossIconMapping: Record<string, string> = {
  error: 'crossRed',
  info: 'crossBlue',
  success: 'crossGreen',
  warning: 'crossYellow',
  none: 'crossBlack'
};

export const cities = [
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'SD', label: 'Springdale' },
  { label: 'Lagos,Cairo,Kinshasa', value: 'Lagos,Cairo,Kinshasa' },
  { label: 'Giza', value: 'Giza' },
  { label: 'Luanda', value: 'Luanda' },
  { label: 'Dar es Salaam', value: 'Dar es Salaam' },
  { label: 'Khartoum', value: 'Khartoum' },
  { label: 'Johannesburg', value: 'Johannesburg' },
  { label: 'Abidjan', value: 'Abidjan' },
  { label: 'Alexandria', value: 'Alexandria' },
  { label: 'Addis Ababa', value: 'Addis Ababa' },
  { label: 'Nairobi', value: 'Nairobi' },
  { label: 'Cape Town', value: 'Cape Town' },
  { label: 'Yaoundé', value: 'Yaoundé' },
  { label: 'Kano', value: 'Kano' },
  { label: 'East Rand (Ekurhuleni)', value: 'East Rand (Ekurhuleni)' },
  { label: 'Douala', value: 'Douala' },
  { label: 'Casablanca', value: 'Casablanca' },
  { label: 'Ibadan', value: 'Ibadan' },
  { label: 'Antananarivo', value: 'Antananarivo' },
  { label: 'Abuja', value: 'Abuja' },
  { label: 'Kampala', value: 'Kampala' },
  { label: 'Kumasi', value: 'Kumasi' },
  { label: 'Dakar', value: 'Dakar' },
  { label: 'Port Harcourt', value: 'Port Harcourt' },
  { label: 'Durban (eThekwini)', value: 'Durban (eThekwini)' },
  { label: 'Ouagadougou', value: 'Ouagadougou' },
  { label: 'Lusaka', value: 'Lusaka' },
  { label: 'Algiers', value: 'Algiers' },
  { label: 'Bamako', value: 'Bamako' },
  { label: 'Omdurman', value: 'Omdurman' },
  { label: 'Mbuji-Mayi', value: 'Mbuji-Mayi' },
  { label: 'Pretoria (Tshwane)', value: 'Pretoria (Tshwane)' },
  { label: 'Lubumbashi', value: 'Lubumbashi' },
  { label: 'Accra', value: 'Accra' },
  { label: 'Brazzaville', value: 'Brazzaville' },
  { label: 'Mogadishu', value: 'Mogadishu' },
  { label: 'Tunis', value: 'Tunis' },
  { label: 'Conakry', value: 'Conakry' },
  { label: 'Rabat', value: 'Rabat' },
  { label: 'Lomé', value: 'Lomé' },
  { label: 'Benin City', value: 'Benin City' },
  { label: 'Matola', value: 'Matola' },
  { label: 'Monrovia', value: 'Monrovia' },
  { label: 'Kananga', value: 'Kananga' },
  { label: 'Harare', value: 'Harare' },
  { label: 'Onitsha', value: 'Onitsha' },
  { label: "N'Djamena", value: "N'Djamena" },
  { label: 'Nouakchott', value: 'Nouakchott' },
  { label: 'Mombasa', value: 'Mombasa' },
  { label: 'Niamey', value: 'Niamey' },
  { label: 'Kisangani', value: 'Kisangani' },
  { label: 'Pointe-Noire', value: 'Pointe-Noire' },
  {
    label: 'Gqeberha (Port Elizabeth)',
    value: 'Gqeberha (Port Elizabeth)'
  },
  { label: 'Tangier', value: 'Tangier' },
  { label: 'Freetown', value: 'Freetown' },
  { label: 'Fez', value: 'Fez' },
  { label: 'Uyo', value: 'Uyo' },
  { label: 'Mwanza', value: 'Mwanza' },
  { label: 'Lilongwe', value: 'Lilongwe' },
  { label: 'Kigali', value: 'Kigali' },
  { label: 'Bukavu', value: 'Bukavu' },
  { label: 'Abomey-Calavi', value: 'Abomey-Calavi' },
  { label: 'Nnewi', value: 'Nnewi' },
  { label: 'Tripoli', value: 'Tripoli' },
  { label: 'Kaduna', value: 'Kaduna' },
  { label: 'Aba', value: 'Aba' },
  { label: 'Bujumbura', value: 'Bujumbura' },
  { label: 'Maputo', value: 'Maputo' },
  { label: 'Hargeisa', value: 'Hargeisa' },
  { label: 'Bobo Dioulasso', value: 'Bobo Dioulasso' },
  { label: 'Shubra el-Kheima', value: 'Shubra el-Kheima' },
  { label: 'Ikorodu', value: 'Ikorodu' },
  { label: 'Asmara', value: 'Asmara' },
  { label: 'Marrakesh', value: 'Marrakesh' },
  { label: 'Tshikapa', value: 'Tshikapa' },
  { label: 'Nyala', value: 'Nyala' },
  { label: 'Ilorin', value: 'Ilorin' },
  { label: 'Blantyre', value: 'Blantyre' },
  { label: 'Agadir', value: 'Agadir' },
  { label: 'Misratah', value: 'Misratah' },
  { label: 'Owerri', value: 'Owerri' },
  { label: 'Warri', value: 'Warri' },
  { label: 'Jos', value: 'Jos' },
  { label: 'Bangui', value: 'Bangui' },
  { label: 'Nampula', value: 'Nampula' },
  { label: 'Oran', value: 'Oran' },
  { label: 'West Rand', value: 'West Rand' },
  { label: 'Lubango', value: 'Lubango' },
  { label: 'Cabinda', value: 'Cabinda' },
  { label: 'Umuahia', value: 'Umuahia' },
  { label: 'Libreville', value: 'Libreville' },
  { label: 'Benghazi', value: 'Benghazi' },
  { label: 'Maiduguri', value: 'Maiduguri' },
  { label: 'Enugu', value: 'Enugu' },
  { label: 'Lokoja', value: 'Lokoja' },
  { label: 'Vereeniging (Emfuleni)', value: 'Vereeniging (Emfuleni)' },
  { label: 'Benguela', value: 'Benguela' },
  { label: 'Bunia', value: 'Bunia' },
  { label: 'Zanzibar', value: 'Zanzibar' },
  { label: 'São Paulo', value: 'São Paulo' },
  { label: 'Mumbai', value: 'Mumbai' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Bangalore', value: 'Bangalore' },
  { label: 'Hyderabad', value: 'Hyderabad' },
  { label: 'Ahmedabad', value: 'Ahmedabad' },
  { label: 'Chennai', value: 'Chennai' },
  { label: 'Kolkata', value: 'Kolkata' },
  { label: 'Surat', value: 'Surat' },
  { label: 'Pune', value: 'Pune' },
  { label: 'Jaipur', value: 'Jaipur' },
  { label: 'Lucknow', value: 'Lucknow' },
  { label: 'Kanpur', value: 'Kanpur' },
  { label: 'Nagpur', value: 'Nagpur' },
  { label: 'Indore', value: 'Indore' },
  { label: 'Thane', value: 'Thane' },
  { label: 'Bhopal', value: 'Bhopal' },
  { label: 'Visakhapatnam', value: 'Visakhapatnam' },
  { label: 'Pimpri-Chinchwad', value: 'Pimpri-Chinchwad' },
  { label: 'Patna', value: 'Patna' },
  { label: 'Vadodara', value: 'Vadodara' },
  { label: 'Ghaziabad', value: 'Ghaziabad' },
  { label: 'Ludhiana', value: 'Ludhiana' },
  { label: 'Agra', value: 'Agra' },
  { label: 'Nashik', value: 'Nashik' },
  { label: 'Faridabad', value: 'Faridabad' },
  { label: 'Meerut', value: 'Meerut' },
  { label: 'Rajkot', value: 'Rajkot' },
  { label: 'Kalyan-Dombivali', value: 'Kalyan-Dombivali' },
  { label: 'Vasai-Virar', value: 'Vasai-Virar' },
  { label: 'Varanasi', value: 'Varanasi' },
  { label: 'Srinagar', value: 'Srinagar' },
  { label: 'Aurangabad', value: 'Aurangabad' },
  { label: 'Dhanbad', value: 'Dhanbad' },
  { label: 'Amritsar', value: 'Amritsar' },
  { label: 'Navi Mumbai', value: 'Navi Mumbai' },
  { label: 'Allahabad', value: 'Allahabad' },
  { label: 'Howrah', value: 'Howrah' },
  { label: 'Ranchi', value: 'Ranchi' },
  { label: 'Jabalpur', value: 'Jabalpur' },
  { label: 'Gwalior', value: 'Gwalior' },
  { label: 'Coimbatore', value: 'Coimbatore' },
  { label: 'Vijayawada', value: 'Vijayawada' },
  { label: 'Jodhpur', value: 'Jodhpur' },
  { label: 'Madurai', value: 'Madurai' },
  { label: 'Raipur', value: 'Raipur' },
  { label: 'Kota', value: 'Kota' },
  { label: 'Guwahati', value: 'Guwahati' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Thiruvananthapuram', value: 'Thiruvananthapuram' },
  { label: 'Solapur', value: 'Solapur' },
  { label: 'Hubballi-Dharwad', value: 'Hubballi-Dharwad' },
  { label: 'Tiruchirappalli[6]', value: 'Tiruchirappalli[6]' },
  { label: 'Tiruppur', value: 'Tiruppur' },
  { label: 'Moradabad', value: 'Moradabad' },
  { label: 'Mysore', value: 'Mysore' },
  { label: 'Bareily', value: 'Bareily' },
  { label: 'Gurgaon', value: 'Gurgaon' },
  { label: 'Aligarh', value: 'Aligarh' },
  { label: 'Jalandhar', value: 'Jalandhar' },
  { label: 'Bhubaneswar', value: 'Bhubaneswar' },
  { label: 'Salem', value: 'Salem' },
  { label: 'Mira-Bhayandar', value: 'Mira-Bhayandar' },
  { label: 'Warangal', value: 'Warangal' },
  { label: 'Guntur', value: 'Guntur' },
  { label: 'Bhiwandi', value: 'Bhiwandi' },
  { label: 'Saharanpur', value: 'Saharanpur' },
  { label: 'Gorakhpur', value: 'Gorakhpur' },
  { label: 'Bikaner', value: 'Bikaner' },
  { label: 'Amravati', value: 'Amravati' },
  { label: 'Noida', value: 'Noida' },
  { label: 'Jamshedpur', value: 'Jamshedpur' },
  { label: 'Bhilai', value: 'Bhilai' },
  { label: 'Cuttack', value: 'Cuttack' },
  { label: 'Firozabad', value: 'Firozabad' },
  { label: 'Kochi', value: 'Kochi' },
  { label: 'Nellore', value: 'Nellore' },
  { label: 'Bhavnagar', value: 'Bhavnagar' },
  { label: 'Dehradun', value: 'Dehradun' },
  { label: 'Durgapur', value: 'Durgapur' },
  { label: 'Asansol', value: 'Asansol' },
  { label: 'Rourkela', value: 'Rourkela' },
  { label: 'Nanded', value: 'Nanded' },
  { label: 'Kolhapur', value: 'Kolhapur' },
  { label: 'Ajmer', value: 'Ajmer' },
  { label: 'Akola', value: 'Akola' },
  { label: 'Gulbarga', value: 'Gulbarga' },
  { label: 'Jamnagar', value: 'Jamnagar' },
  { label: 'Ujjain', value: 'Ujjain' },
  { label: 'Loni', value: 'Loni' },
  { label: 'Siliguri', value: 'Siliguri' },
  { label: 'Jhansi', value: 'Jhansi' },
  { label: 'Ulhasnagar', value: 'Ulhasnagar' },
  { label: 'Jammu', value: 'Jammu' },
  { label: 'Sangli-Miraj & Kupwad', value: 'Sangli-Miraj & Kupwad' },
  { label: 'Mangalore', value: 'Mangalore' },
  { label: 'Erode', value: 'Erode' },
  { label: 'Belgaum', value: 'Belgaum' },
  { label: 'Ambattur', value: 'Ambattur' },
  { label: 'Tirunelveli', value: 'Tirunelveli' }
];

export const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WF', label: 'West Forest' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
  {
    label: 'Andaman and Nicobar Islands',
    value: 'Andaman and Nicobar Islands'
  },
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Chhattisgarh', value: 'Chhattisgarh' },
  {
    label: 'Dadra and Nagar Haveli and Daman and Diu',
    value: 'Dadra and Nagar Haveli and Daman and Diu'
  },
  { label: 'Goa', value: 'Goa' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Jammu and Kashmir', value: 'Jammu and Kashmir' },
  { label: 'Jharkhand', value: 'Jharkhand' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Kerela', value: 'Kerela' },
  { label: 'Ladakh', value: 'Ladakh' },
  { label: 'Lakshadweep', value: 'Lakshadweep' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Manipur', value: 'Manipur' },
  { label: 'Meghalaya', value: 'Meghalaya' },
  { label: 'Mizoram', value: 'Mizoram' },
  { label: 'Nagaland', value: 'Nagaland' },
  { label: 'NCT of Delhi', value: 'NCT of Delhi' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Puducherry', value: 'Puducherry' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Sikkim', value: 'Sikkim' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'Telangana', value: 'Telangana' },
  { label: 'Tripura', value: 'Tripura' },
  { label: 'Uttarakhand', value: 'Uttarakhand' },
  { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
  { label: 'West Bengal', value: 'West Bengal' }
];

export const countries = [
  {
    value: 'AD',
    label: 'Andorra'
  },
  {
    value: 'AE',
    label: 'United Arab Emirates'
  },
  {
    value: 'AF',
    label: 'Afghanistan'
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda'
  },
  {
    value: 'AI',
    label: 'Anguilla'
  },
  {
    value: 'AL',
    label: 'Albania'
  },
  {
    value: 'AM',
    label: 'Armenia'
  },
  {
    value: 'AN',
    label: 'Netherlands Antilles'
  },
  {
    value: 'AO',
    label: 'Angola'
  },
  {
    value: 'AQ',
    label: 'Antarctica'
  },
  {
    value: 'AR',
    label: 'Argentina'
  },
  {
    value: 'AS',
    label: 'American Samoa'
  },
  {
    value: 'AT',
    label: 'Austria'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'AW',
    label: 'Aruba'
  },
  {
    value: 'AZ',
    label: 'Azerbaijan'
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina'
  },
  {
    value: 'BB',
    label: 'Barbados'
  },
  {
    value: 'BD',
    label: 'Bangladesh'
  },
  {
    value: 'BE',
    label: 'Belgium'
  },
  {
    value: 'BF',
    label: 'Burkina Faso'
  },
  {
    value: 'BG',
    label: 'Bulgaria'
  },
  {
    value: 'BH',
    label: 'Bahrain'
  },
  {
    value: 'BI',
    label: 'Burundi'
  },
  {
    value: 'BJ',
    label: 'Benin'
  },
  {
    value: 'BM',
    label: 'Bermuda'
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam'
  },
  {
    value: 'BO',
    label: 'Bolivia'
  },
  {
    value: 'BR',
    label: 'Brazil'
  },
  {
    value: 'BS',
    label: 'Bahama'
  },
  {
    value: 'BT',
    label: 'Bhutan'
  },
  {
    value: 'BV',
    label: 'Bouvet Island'
  },
  {
    value: 'BW',
    label: 'Botswana'
  },
  {
    value: 'BY',
    label: 'Belarus'
  },
  {
    value: 'BZ',
    label: 'Belize'
  },
  {
    value: 'CA',
    label: 'Canada'
  },
  {
    value: 'CC',
    label: 'Cocos Islands'
  },
  {
    value: 'CF',
    label: 'Central African Republic'
  },
  {
    value: 'CG',
    label: 'Congo'
  },
  {
    value: 'CH',
    label: 'Switzerland'
  },
  {
    value: 'CI',
    label: 'Ivory Coast'
  },
  {
    value: 'CK',
    label: 'Cook Iislands'
  },
  {
    value: 'CL',
    label: 'Chile'
  },
  {
    value: 'CM',
    label: 'Cameroon'
  },
  {
    value: 'CN',
    label: 'China'
  },
  {
    value: 'CO',
    label: 'Colombia'
  },
  {
    value: 'CR',
    label: 'Costa Rica'
  },
  {
    value: 'CU',
    label: 'Cuba'
  },
  {
    value: 'CV',
    label: 'Cape Verde'
  },
  {
    value: 'CX',
    label: 'Christmas Island'
  },
  {
    value: 'CY',
    label: 'Cyprus'
  },
  {
    value: 'CZ',
    label: 'Czech Republic'
  },
  {
    value: 'DE',
    label: 'Germany'
  },
  {
    value: 'DJ',
    label: 'Djibouti'
  },
  {
    value: 'DK',
    label: 'Denmark'
  },
  {
    value: 'DM',
    label: 'Dominica'
  },
  {
    value: 'DO',
    label: 'Dominican Republic'
  },
  {
    value: 'DZ',
    label: 'Algeria'
  },
  {
    value: 'EC',
    label: 'Ecuador'
  },
  {
    value: 'EE',
    label: 'Estonia'
  },
  {
    value: 'EG',
    label: 'Egypt'
  },
  {
    value: 'EH',
    label: 'Western Sahara'
  },
  {
    value: 'ER',
    label: 'Eritrea'
  },
  {
    value: 'ES',
    label: 'Spain'
  },
  {
    value: 'ET',
    label: 'Ethiopia'
  },
  {
    value: 'FI',
    label: 'Finland'
  },
  {
    value: 'FJ',
    label: 'Fiji'
  },
  {
    value: 'FK',
    label: 'Falkland Islands'
  },
  {
    value: 'FM',
    label: 'Micronesia'
  },
  {
    value: 'FO',
    label: 'Faroe Islands'
  },
  {
    value: 'FR',
    label: 'France'
  },
  {
    value: 'FX',
    label: 'France, Metropolitan'
  },
  {
    value: 'GA',
    label: 'Gabon'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'GD',
    label: 'Grenada'
  },
  {
    value: 'GE',
    label: 'Georgia'
  },
  {
    value: 'GF',
    label: 'French Guiana'
  },
  {
    value: 'GH',
    label: 'Ghana'
  },
  {
    value: 'GI',
    label: 'Gibraltar'
  },
  {
    value: 'GL',
    label: 'Greenland'
  },
  {
    value: 'GM',
    label: 'Gambia'
  },
  {
    value: 'GN',
    label: 'Guinea'
  },
  {
    value: 'GP',
    label: 'Guadeloupe'
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea'
  },
  {
    value: 'GR',
    label: 'Greece'
  },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'GT',
    label: 'Guatemala'
  },
  {
    value: 'GU',
    label: 'Guam'
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau'
  },
  {
    value: 'GY',
    label: 'Guyana'
  },
  {
    value: 'HK',
    label: 'Hong Kong'
  },
  {
    value: 'HM',
    label: 'Heard & McDonald Islands'
  },
  {
    value: 'HN',
    label: 'Honduras'
  },
  {
    value: 'HR',
    label: 'Croatia'
  },
  {
    value: 'HT',
    label: 'Haiti'
  },
  {
    value: 'HU',
    label: 'Hungary'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'IE',
    label: 'Ireland'
  },
  {
    value: 'IL',
    label: 'Israel'
  },
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory'
  },
  {
    value: 'IQ',
    label: 'Iraq'
  },
  {
    value: 'IR',
    label: 'Islamic Republic of Iran'
  },
  {
    value: 'IS',
    label: 'Iceland'
  },
  {
    value: 'IT',
    label: 'Italy'
  },
  {
    value: 'JM',
    label: 'Jamaica'
  },
  {
    value: 'JO',
    label: 'Jordan'
  },
  {
    value: 'JP',
    label: 'Japan'
  },
  {
    value: 'KE',
    label: 'Kenya'
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan'
  },
  {
    value: 'KH',
    label: 'Cambodia'
  },
  {
    value: 'KI',
    label: 'Kiribati'
  },
  {
    value: 'KM',
    label: 'Comoros'
  },
  {
    value: 'KN',
    label: 'St. Kitts and Nevis'
  },
  {
    value: 'KP',
    label: "Korea, Democratic People's Republic of"
  },
  {
    value: 'KR',
    label: 'Korea, Republic of'
  },
  {
    value: 'KW',
    label: 'Kuwait'
  },
  {
    value: 'KY',
    label: 'Cayman Islands'
  },
  {
    value: 'KZ',
    label: 'Kazakhstan'
  },
  {
    value: 'LA',
    label: "Lao People's Democratic Republic"
  },
  {
    value: 'LB',
    label: 'Lebanon'
  },
  {
    value: 'LC',
    label: 'Saint Lucia'
  },
  {
    value: 'LI',
    label: 'Liechtenstein'
  },
  {
    value: 'LK',
    label: 'Sri Lanka'
  },
  {
    value: 'LR',
    label: 'Liberia'
  },
  {
    value: 'LS',
    label: 'Lesotho'
  },
  {
    value: 'LT',
    label: 'Lithuania'
  },
  {
    value: 'LU',
    label: 'Luxembourg'
  },
  {
    value: 'LV',
    label: 'Latvia'
  },
  {
    value: 'LY',
    label: 'Libyan Arab Jamahiriya'
  },
  {
    value: 'MA',
    label: 'Morocco'
  },
  {
    value: 'MC',
    label: 'Monaco'
  },
  {
    value: 'MD',
    label: 'Moldova, Republic of'
  },
  {
    value: 'MG',
    label: 'Madagascar'
  },
  {
    value: 'MH',
    label: 'Marshall Islands'
  },
  {
    value: 'ML',
    label: 'Mali'
  },
  {
    value: 'MN',
    label: 'Mongolia'
  },
  {
    value: 'MM',
    label: 'Myanmar'
  },
  {
    value: 'MO',
    label: 'Macau'
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'MQ',
    label: 'Martinique'
  },
  {
    value: 'MR',
    label: 'Mauritania'
  },
  {
    value: 'MS',
    label: 'Monserrat'
  },
  {
    value: 'MT',
    label: 'Malta'
  },
  {
    value: 'MU',
    label: 'Mauritius'
  },
  {
    value: 'MV',
    label: 'Maldives'
  },
  {
    value: 'MW',
    label: 'Malawi'
  },
  {
    value: 'MX',
    label: 'Mexico'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'MZ',
    label: 'Mozambique'
  },
  {
    value: 'NA',
    label: 'Namibia'
  },
  {
    value: 'NC',
    label: 'New Caledonia'
  },
  {
    value: 'NE',
    label: 'Niger'
  },
  {
    value: 'NF',
    label: 'Norfolk Island'
  },
  {
    value: 'NG',
    label: 'Nigeria'
  },
  {
    value: 'NI',
    label: 'Nicaragua'
  },
  {
    value: 'NL',
    label: 'Netherlands'
  },
  {
    value: 'NO',
    label: 'Norway'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'NR',
    label: 'Nauru'
  },
  {
    value: 'NU',
    label: 'Niue'
  },
  {
    value: 'NZ',
    label: 'New Zealand'
  },
  {
    value: 'OM',
    label: 'Oman'
  },
  {
    value: 'PA',
    label: 'Panama'
  },
  {
    value: 'PE',
    label: 'Peru'
  },
  {
    value: 'PF',
    label: 'French Polynesia'
  },
  {
    value: 'PG',
    label: 'Papua New Guinea'
  },
  {
    value: 'PH',
    label: 'Philippines'
  },
  {
    value: 'PK',
    label: 'Pakistan'
  },
  {
    value: 'PL',
    label: 'Poland'
  },
  {
    value: 'PM',
    label: 'St. Pierre & Miquelon'
  },
  {
    value: 'PN',
    label: 'Pitcairn'
  },
  {
    value: 'PR',
    label: 'Puerto Rico'
  },
  {
    value: 'PT',
    label: 'Portugal'
  },
  {
    value: 'PW',
    label: 'Palau'
  },
  {
    value: 'PY',
    label: 'Paraguay'
  },
  {
    value: 'QA',
    label: 'Qatar'
  },
  {
    value: 'RE',
    label: 'Reunion'
  },
  {
    value: 'RO',
    label: 'Romania'
  },
  {
    value: 'RU',
    label: 'Russian Federation'
  },
  {
    value: 'RW',
    label: 'Rwanda'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  },
  {
    value: 'SB',
    label: 'Solomon Islands'
  },
  {
    value: 'SC',
    label: 'Seychelles'
  },
  {
    value: 'SD',
    label: 'Sudan'
  },
  {
    value: 'SE',
    label: 'Sweden'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'SH',
    label: 'St. Helena'
  },
  {
    value: 'SI',
    label: 'Slovenia'
  },
  {
    value: 'SJ',
    label: 'Svalbard & Jan Mayen Islands'
  },
  {
    value: 'SK',
    label: 'Slovakia'
  },
  {
    value: 'SL',
    label: 'Sierra Leone'
  },
  {
    value: 'SM',
    label: 'San Marino'
  },
  {
    value: 'SN',
    label: 'Senegal'
  },
  {
    value: 'SO',
    label: 'Somalia'
  },
  {
    value: 'SR',
    label: 'Suriname'
  },
  {
    value: 'ST',
    label: 'Sao Tome & Principe'
  },
  {
    value: 'SV',
    label: 'El Salvador'
  },
  {
    value: 'SY',
    label: 'Syrian Arab Republic'
  },
  {
    value: 'SZ',
    label: 'Swaziland'
  },
  {
    value: 'TC',
    label: 'Turks & Caicos Islands'
  },
  {
    value: 'TD',
    label: 'Chad'
  },
  {
    value: 'TF',
    label: 'French Southern Territories'
  },
  {
    value: 'TG',
    label: 'Togo'
  },
  {
    value: 'TH',
    label: 'Thailand'
  },
  {
    value: 'TJ',
    label: 'Tajikistan'
  },
  {
    value: 'TK',
    label: 'Tokelau'
  },
  {
    value: 'TM',
    label: 'Turkmenistan'
  },
  {
    value: 'TN',
    label: 'Tunisia'
  },
  {
    value: 'TO',
    label: 'Tonga'
  },
  {
    value: 'TP',
    label: 'East Timor'
  },
  {
    value: 'TR',
    label: 'Turkey'
  },
  {
    value: 'TT',
    label: 'Trinidad & Tobago'
  },
  {
    value: 'TV',
    label: 'Tuvalu'
  },
  {
    value: 'TW',
    label: 'Taiwan, Province of China'
  },
  {
    value: 'TZ',
    label: 'Tanzania, United Republic of'
  },
  {
    value: 'UA',
    label: 'Ukraine'
  },
  {
    value: 'UG',
    label: 'Uganda'
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands'
  },
  {
    value: 'US',
    label: 'United States of America'
  },
  {
    value: 'UY',
    label: 'Uruguay'
  },
  {
    value: 'UZ',
    label: 'Uzbekistan'
  },
  {
    value: 'VA',
    label: 'Vatican City State'
  },
  {
    value: 'VC',
    label: 'St. Vincent & the Grenadines'
  },
  {
    value: 'VE',
    label: 'Venezuela'
  },
  {
    value: 'VG',
    label: 'British Virgin Islands'
  },
  {
    value: 'VI',
    label: 'United States Virgin Islands'
  },
  {
    value: 'VN',
    label: 'Viet Nam'
  },
  {
    value: 'VU',
    label: 'Vanuatu'
  },
  {
    value: 'WF',
    label: 'Wallis & Futuna Islands'
  },
  {
    value: 'WS',
    label: 'Samoa'
  },
  {
    value: 'YE',
    label: 'Yemen'
  },
  {
    value: 'YT',
    label: 'Mayotte'
  },
  {
    value: 'YU',
    label: 'Yugoslavia'
  },
  {
    value: 'ZA',
    label: 'South Africa'
  },
  {
    value: 'ZM',
    label: 'Zambia'
  },
  {
    value: 'ZR',
    label: 'Zaire'
  },
  {
    value: 'ZW',
    label: 'Zimbabwe'
  },
  {
    value: 'ZZ',
    label: 'Unknown or unspecified country'
  }
];

export const ADDRESS_TYPE = [
  { value: 'OFC1', label: 'OFFICE1' },
  { value: 'OFC2', label: 'OFFICE2' },
  { value: 'FAC1', label: 'FACTORY1' },
  { value: 'FAC2', label: 'FACTORY2' },
  { value: 'OTHE', label: 'OTHERS' }
];

export const SUPPLIER_TYPE = [
  {
    label: 'Indian',
    value: 'INDIAN'
  },
  {
    label: 'Foreigner',
    value: 'FOREIGN'
  }
];

export const UPLOAD_DOCS_MAP = [
  { value: 'CUSTOMINV', label: 'Custom Invoice' },
  { value: 'PREPACKINGLIST', label: 'Pre Shipment Packing List' },
  { value: 'FUMIGATIONCERT', label: 'Fumigation Certificate' },
  { value: 'EULETTER', label: 'End use letter' },
  { value: 'SCOMET', label: 'Scomet' },
  { value: 'NONDGDECLARATION', label: 'Non DG Declaration' },
  { value: 'COMMINV', label: 'Commercial Invoice' },
  { value: 'PACKINGLIST', label: 'Packing List' },
  { value: 'BOE', label: 'Bill of Exchange' },
  { value: 'CRL', label: 'Customer Request Letter' },
  { value: 'SB', label: 'Shipping Bill' },
  { value: 'LEO', label: 'LEO Copy' },
  { value: 'BLDRAFT', label: 'B/L Draft' },
  { value: 'BL', label: 'Final B/L' },
  { value: 'INSURANCE', label: 'Insurance Certificate' },
  { value: 'COC', label: 'Certificate of Conformity' },
  { value: 'INSPECTIONREPORT', label: 'Inspection Report' },
  { value: 'ORDERACCEPTANCEPROOF', label: 'Customer Order Acceptance Proof' },
  { value: 'DOCUMENTSCOURIERSLIP', label: 'Documents Courier Slip' },
  { value: 'COAPRE', label: 'Pre-Shipment Certificate of Analysis' },
  { value: 'COAPOST', label: 'Post-Shipment Certificate of Analysis' },
  { value: 'MSDS', label: 'MSDS' },
  { value: 'ISO', label: 'ISO Certificate' },
  { value: 'IIP', label: 'IIP Certificate' },
  { value: 'COO', label: 'Certificate of origin' }
];

export const BUSINESS_TYPE = [
  {
    label: 'Trader',
    value: 'TR'
  },
  {
    label: 'Manufacturer',
    value: 'MF'
  }
];

export const SUPPLIER_DOCUMENT_TYPE = [
  { label: 'ISO Certificate', value: 'ISOCER' },
  { label: 'Manufacturing License', value: 'MFGLIC' },
  { label: 'GST Certificate', value: 'GSTCER' },
  { label: 'Other', value: 'OTHER' }
];

export const SUPPLIER_GRADE_DOCUMENT_TYPE = [
  { label: 'TDS', value: 'TDS' }
  // { label: 'IIP', value: 'IIP' }
];

export const CUSTOMER_DOCUMENT_TYPE = [
  { label: 'Bank Certificate', value: 'BANCER' },
  { label: 'Registration Certificate', value: 'REGCER' },
  { label: 'Incorporation Certificate', value: 'INCCER' },
  { label: 'Other', value: 'OTHER' }
];

export const MAX_FILE_SIZE = 10485760; // 10 MB

export const SUPPORTED_FORMATS = [
  'application/ms-doc',
  'application/pdf',
  'application/doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword'
];

export const SUPPLY_TYPE = [
  {
    label: 'MTT',
    value: 'MTT'
  },
  { label: 'Export from India', value: 'IND' }
];

export const TRANSPORTATION_MODE = [
  {
    label: 'Air',
    value: 'AIR'
  },
  {
    label: 'Sea',
    value: 'SEA'
  }
];

export const INCO_TERMS = [
  { label: 'CNF', value: 'CNF' },
  { label: 'CFR', value: 'CFR' },
  { label: 'CIF', value: 'CIF' },
  { label: 'CPT', value: 'CPT' },
  { label: 'CIP', value: 'CIP' },
  { label: 'DDP', value: 'DDP' },
  { label: 'DAP', value: 'DAP' },
  { label: 'EXW', value: 'EXW' },
  { label: 'FCA', value: 'FCA' },
  { label: 'FAS', value: 'FAS' },
  { label: 'FOB', value: 'FOB' }
];

export const CURRENCY = [
  { value: 'USD', label: 'USD' },
  { value: 'INR', label: 'INR' },
  { value: 'EURO', label: 'EURO' },
  { value: 'AED', label: 'AED' }
];

export const PAYMENT_TERMS = [
  { value: 'LC', label: '100% LC Sight' },
  { value: 'LC30', label: '100% LC 30' },
  { value: 'LC60', label: '100% LC 60' },
  { value: 'LC90', label: '100% LC 90' },
  { value: 'Advance', label: '100% Advance' },
  { value: 'Scanned', label: '100% Against Scanned Documents' },
  { value: 'CAD', label: '100% CAD' },
  { value: 'DA30', label: '100% DA-30' },
  { value: 'DA45', label: '100% DA-45' },
  { value: 'DA60', label: '100% DA-60' },
  { value: 'DA75', label: '100% DA-75' },
  { value: 'DA90', label: '100% DA-90' },
  { value: 'DA120', label: '100% DA-120' },
  { value: '10Adv90Scan', label: '10% Advance, 90% Scanned Documents' },
  { value: '15Adv85Scan', label: '15% Advance, 85% Scanned Documents' },
  { value: '20Adv80Scan', label: '20% Advance, 80% Scanned Documents' },
  { value: '30Adv70Scan', label: '30% Advance, 70% Scanned Documents' },
  { value: '20Adv80CAD', label: '20% Advance, 80% CAD' },
  { value: '30Adv70CAD', label: '30% Advance, 70% CAD' }
];

export const PAYMENT_TYPE = [
  { label: 'Advance', value: 'Advance' },
  { label: 'Non-Advance', value: 'NonAdvance' }
];

export const PO_PAYMENT_TERMS = [
  { value: 'LC', label: 'LC' },
  { value: 'ADVANCE', label: 'Advance' },
  { value: 'AGADEL', label: 'Against Delivery' },
  { value: 'Net-15', label: 'Net-15' },
  { value: 'Net-30', label: 'Net-30' },
  { value: 'Net-60', label: 'Net-60' },
  { value: 'Net-90', label: 'Net-90' },
  { value: '10ADV90SCAN', label: '10% Advance, 90% Against Scanned Documents' },
  { value: '15ADV85SCAN', label: '15% Advance, 85% Against Scanned Documents' },
  { value: '20ADV80SCAN', label: '20% Advance, 80% Against Scanned Documents' },
  { value: '30ADV70SCAN', label: '30% Advance, 70% Against Scanned Documents' },
  { value: '100SCAN', label: '100% Against Scanned Documents' },
  { value: '50ADV50DEL', label: '50% Advance, 50% Against Delivery' }
];

export const DELIVERY_TERMS = [
  { value: 'EXW', label: 'EX-Works' },
  { value: 'FOR', label: 'FOR' },
  { value: 'CFR', label: 'CFR' },
  { value: 'CIF', label: 'CIF' },
  { label: 'FOB', value: 'FOB' }
];

export const GST_TYPE = [
  { value: 'MEXPORT', label: 'Merchant Export' },
  { value: 'EXPORT', label: 'Export' },
  { value: 'DOMESTIC', label: 'DOMESTIC' }
];

export const GST_RATE = [
  { value: 0.1, label: 0.1 },
  { value: 5, label: 5 },
  { value: 18, label: 18 },
  { value: 0, label: 0 }
];

export const QC_LOCATION = [
  { value: 'Factory', label: 'Factory' },
  { value: 'CFS', label: 'CFS' }
];

export const DATE_FORMAT = 'do LLL yyyy';

export const CUSTOM_ITEMS = [
  {
    custom_item_name: '',
    custom_item_description: '',
    custom_item_quantity: '',
    custom_item_rate: '',
    custom_item_amount: ''
  }
];

export function PeopleType(people: string) {
  if (people.split('_')[1] === 'rep') {
    return 'Representative';
  } else {
    return 'Head';
  }
}

export interface IDocType {
  order_related: string;
  order_item_related: string;
  product_grade_related: string;
}

export const SHIPMENT_DOCUMENTS_TYPE_MAP: any = {
  order_related: 'order_related',
  order_item_related: 'order_item_related',
  product_grade_related: 'product_grade_related'
};

export const TASKS_MAP = {
  generatePI: 'Generate Proforma Invoice',
  reviewPI: 'Review Proforma Invoice',
  rectifyPI: 'Rectify Proforma Invoice',
  generateBOE: 'Generate BoE',
  generateCRL: 'Generate CRL',
  generatePO: 'Generate PO',
  reviewPO: 'Review Purchase Order',
  rectifyPO: 'Rectify Purchase Order',
  preShipmentDoc: 'Upload pre shipment documents',
  postShipmentDoc: 'Upload post shipment documents',
  exportClearance: 'Upload export clearance documments',
  paymentProof: 'Upload order payment proof',
  addTransportDetails: 'Add local transportation details',
  materialLiftingConfirmation: 'Material lifting from factory confirmation',
  generateCI: 'Generate Custom Invoice',
  uploadECGCandDNB: 'Add ECGC D&B information',
  uploadCustomerOrderAcceptance: 'Upload customer acceptance document',
  addAllSuppliers: 'Add suppliers for all products of this order',
  vesselDispatch: 'Add vessel booking dates',
  upadteGateIn: 'Update Gate In',
  updateShipmentClearance: 'Update shipment clearance at POD',
  addCourierDetails: 'Add courrier details',
  rectifyOrder: 'Rectify order',
  reviewOrder: 'Review order',
  addLiftingDates: 'Add lifting dates',
  addRodtepDrawback: 'Add rodtep and drawback',
  assignPersonnel: 'Assign personnel',
  addContainerData: 'Add container details',
  addBLDetails: 'Add BL details',
  generateCommercialInvoice: 'Generate Commercial Invoice',
  generateCOA: 'Generate COA',
  proofOfExport: 'Add proof of export',
  generateCOO: 'Generate COO',
  verifyPackingDetails: 'Verify packaging details',
  rectifyPackingDetails: 'Rectify packaging details',
  approvePackingDetails: 'Approve packaging details',
  addBatchNumber: 'Add batch number',
  generatePreShipmentPackingList: 'Generate pre shipment packing list',
  generatePostShipmentPackingList: 'Generate post shipment packing list',
  generateShippingInstructions: 'Generate shipping instruction',
  uploadBLDraft: 'Upload draft BL document',
  addOperationCosts: 'Add operational costs',
  addEstimate: 'Add task estimates',
  uploadLCDetails: 'Upload LC details'
};

export const SHIPMENT_MAP: any = {
  preShipment: 'pre-shipment',
  postShipment: 'post-shipment',
  exportClearance: 'export-clearance'
};

export function convertDateString(date: Date | null) {
  if (date) return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  else return null;
}

export function convertShipmentType(name: string) {
  if (name) {
    const splittedName = name.split('-');
    return `${splittedName[0]} ${splittedName[1]}`;
  }
}

export function getLabelFromValue(value: string, array: Array<any>) {
  const foundObject = array.find((obj) => obj.value === value);
  return foundObject ? foundObject.label : null;
}

export const DOCUMENT_GENERATION_STATUS = [
  { label: 'DOC_GENERATION_REQUESTED', value: 1 },
  { label: 'DOC_GENERATION_PROCESSING', value: 2 },
  { label: 'DOC_GENERATION_SUCCESS', value: 3 },
  { label: 'DOC_GENERATION_FAILED', value: 4 }
];

export const MAX_ZIP_CODE_LENGTH = 15;

export const ADDRESS_TYPE_CONSTANTS = {
  FACTORY: 'FAC',
  OFFICE: 'OFC',
  OTHER: 'OTH'
};

export const UNITS_OF_WEIGHTS = [
  {
    label: 'KG',
    value: 'KG'
  },
  {
    label: 'MT',
    value: 'MT'
  }
];

export const booleanSelectFields = [
  {
    label: 'No',
    value: false
  },
  {
    label: 'Yes',
    value: true
  }
];

export const CONTAINER_TYPE = [
  { value: '20FTFCL', label: '20 FT FCL' },
  { value: '40FTFCL', label: '40 FT FCL' },
  { value: '40HCFCL', label: '40 HC FCL' },
  { value: 'LCL', label: 'LCL' }
];

export const COA_ORDER_DETAILS_DEFAULT = [
  'Item',
  'Batch Number',
  'Manufacturing Date',
  'Date of Expiry / Retest'
];

export const COA_ITEM_DETAILS_DEFAULT = [
  'Parameter',
  'Unit',
  'Results',
  'Specification',
  'Test Method'
];

export const MAX_FILES_ALLOWED = {
  POOF_OF_EXPORT: 5,
  RFQ: 5
};

export const QUANTITY_INPUT_DISABLE_SWITCH = true;

export const COUNTRY_FIELD_TYPES = {
  COUNTRY_OF_ORIGIN: 'countryOfOrigin',
  COUNTRY_OF_FINAL_DESTINATION: 'countryOfFinalDestination'
};

export const FREIGHT_TYPES = [
  { value: 'TO_PAY', label: 'To Pay' },
  { value: 'PREPAID', label: 'Prepaid' },
  { value: 'COLLECT', label: 'Collect' }
];

export const BL_TYPES = [
  { value: 1, label: 'Seaway BL' },
  { value: 2, label: 'OBL' },
  { value: 3, label: 'HBL' }
  // { value: 4, label: 'Airway BL' }
];

export const remarkSI = {
  obl: `We need Minimum 3 original + 3 n/n copy of b/l duly stamp and signed.`,
  seawayBL: `We need SEAWAY B/L duly stamp and signed.`,
  generalInstructions: `B/l must state (A) Name, address and Tel no. of the Carrying Vessel’s agent at the Port of Destn. (B) Name, address and Tel no. of the carrier.`
};

export const PACKING_GROUP = [
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' }
];

export const USER_TYPES = {
  internal: 'internal',
  customer: 'external',
  supplier: 'supplier'
};

export const MODULE_NAMES = {
  order: 'order',
  supplier: 'supplier',
  customer: 'customer',
  product: 'product',
  task: 'task',
  orderActivity: 'order_activity',
  orderTracking: 'order_tracking',
  orderDocuments: 'order_documents',
  orderPeople: 'order_people',
  orderOverview: 'order_overview',
  orderStuffingPhotos: 'order_stuffing_photos',
  email: 'email',
  enquiry: 'enquiry',
  note: 'note'
};

export const DEFAULT_CROP_LIMIT = 15;

export const DEFAULT_AMOUNT_ROUND_OFF = 2;

export const PORT_FIELD_TYPES = {
  PORT_OF_LOADING: 'portOfLoading',
  PORT_OF_DISCHARGE: 'portOfDischarge',
  PORT_OF_FINAL_DESTINATION: 'portOfFinalDestination'
};

export const COMMUNICATION_TYPE = [
  {
    label: 'Courier',
    value: 'courier'
  },
  {
    label: 'TelEx',
    value: 'telex'
  }
];

export const EmailStatus = {
  1: 'Pending',
  2: 'Sent',
  3: 'Failed',
  4: 'In Progress',
  5: 'Declined'
};

export const TAG_LIMIT_VIEW = 1;

export const MODE_TO_SEND_DOCUMENTS_TO_CUSTOMER = [
  {
    label: 'Share via Email',
    value: 0
  },
  {
    label: 'Share via DOMS',
    value: 1
  }
];

export const EVENT_TRACKING_TYPES = {
  LOGIN_BTN_CLICK: 'LOGIN_BTN_CLICK',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_CLICK: 'FORGOT_PASSWORD_CLICK',
  ORDER_VIEW_CLICK: 'ORDER_VIEW_CLICK',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILED: 'GOOGLE_LOGIN_FAILED',
  PROGRESS_BAR_DETAILED_CLICK: 'PROGRESS_BAR_DETAILED_CLICK',
  ORDER_TAB_SELECT: 'ORDER_TAB_SELECT',
  ORDER_PROGRESS_TAB_SELECT: 'ORDER_PROGRESS_TAB_SELECT',
  ORDER_TRACKING_TAB_SELECT: 'ORDER_TRACKING_TAB_SELECT',
  ORDER_DOCUMENTS_TAB_SELECT: 'ORDER_DOCUMENTS_TAB_SELECT',
  ORDER_PEOPLE_TAB_SELECT: 'ORDER_PEOPLE_TAB_SELECT',
  ORDER_OVERVIEW_TAB_SELECT: 'ORDER_OVERVIEW_TAB_SELECT',
  ORDER_STUFFING_PHOTOS_TAB_SELECT: 'ORDER_STUFFING_PHOTOS_TAB_SELECT'
};

export const SCREEN_TYPE = {
  mobile: {
    maxSize: 480,
    label: 'MOBILE'
  },
  tablet: {
    maxSize: 768,
    label: 'TABLET'
  },
  desktop: {
    maxSize: 1024,
    label: 'DESKTOP'
  }
};

export const TEAM_TAGS = [
  {
    label: 'Sales',
    value: 0
  },
  {
    label: 'Purchase',
    value: 1
  },
  {
    label: 'Finance',
    value: 2
  },
  {
    label: 'Operations',
    value: 3
  },
  {
    label: 'All',
    value: null
  }
];

export const DOCUMENT_TYPES = ['Tax Invoice', 'Proforma Invoice'];

export const PAYMENT_STATUS = {
  REQUESTED: 'requested',
  PAID: 'paid',
  INITIATED: 'initiated'
};
