import Checkbox from '@components/base/checkbox/checkbox';
import { DataGrid, ListPlaceholder, PaginationScroller } from '@components/common';
import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { getFormattedDate, getPermissionFromLocalStorage } from '@helpers/utils';
import { paymentAlert } from '@services/finance.service';
import notify from '@helpers/toastify-helper';

const PaymentRequested = (props: any) => {
  const { data, currentPageNumber, changePage } = props;
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [paginationState, setPaginationState] = useState({
    itemLimit: 10,
    count: 0,
    isLoading: false
  });

  const { itemLimit, count, isLoading } = paginationState;

  const is_finance_team_member = useMemo(
    () => getPermissionFromLocalStorage()?.is_finance_team_member,
    []
  );

  const onPaymentSelection = (e: any, value: any) => {
    setSelectedPayments((prevSelectedPayments) => {
      if (e.target.checked) {
        // Check if the payment is already in the array
        const isAlreadySelected = prevSelectedPayments.some((payment) => payment.id === value.id);

        if (isAlreadySelected) {
          return prevSelectedPayments; // Return the current state without changes
        } else {
          return [...prevSelectedPayments, value];
        }
      } else {
        return prevSelectedPayments.filter((item) => item.id !== value.id);
      }
    });
  };

  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Checkbox
                disabled={!is_finance_team_member}
                onChange={(e) => onPaymentSelection(e, value)}
              />
            </>
          );
        }
      },
      {
        Header: 'Order ID',
        accessor: 'readable_order_id'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Total Order Invoice Amount',
        accessor: 'total_invoice_amount'
      },
      {
        Header: 'Request Amount',
        accessor: 'total_invoice_payable'
      },
      {
        Header: 'Invoice Details',
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: 'Due Date',
        accessor: 'due_date'
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              {is_finance_team_member ? (
                <Button variant="text">Make Payment</Button>
              ) : (
                <Button variant="text" onClick={() => actionButtonHandler(value?.id)}>
                  Send Alert
                </Button>
              )}
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          check_box: item,
          readable_order_id: item?.order_readable_ids?.length
            ? item?.order_readable_ids?.join(', ')
            : item?.order_readable_ids ?? '-',
          supplier: item?.supplier_name,
          total_invoice_amount: `${item.currency} ${item?.total_payable_amount}`,
          total_invoice_payable: `${item.currency} ${item?.amount_to_pay}`,
          invoice: item ?? null,
          due_date: item?.due_date ?? '-',
          action: item ?? null
        }))
      : [];

    return [column, row];
  }, [data]);

  const actionButtonHandler = (selectedPayment?: string) => {
    if (is_finance_team_member) {
      console.log('Make Payment');
    } else {
      sendPaymentAlert(selectedPayment ?? null);
    }
  };

  const sendPaymentAlert = async (id: string | null) => {
    const response = await paymentAlert(id);
    if (response?.success) {
      notify({
        message: 'Alert added successfully'
      });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };

  // const handlePageChange = async (newPage: number) => {
  //   setPaginationState((prev) => ({ ...prev, isLoading: true }));

  //   setOrderState((prevState) => ({ ...prevState, isLoading: false }));
  //   const queryKey = STATE_MAPPING.find((state) => state.value === initialOrderState);
  //   setSearchParams({
  //     ...Object.fromEntries(searchParams),
  //     page: newPage.toString(),
  //     activeTab: queryKey?.key || defaultTab.key
  //   });
  // };

  // const validatePageChange = (event: any, value: number) => {
  //   if (isNaN(value)) return;
  //   if (value === 0 || value > Math.ceil(orderCount / itemLimit)) return;
  //   handlePageChange(value);
  // };

  // useEffect(() => {
  //   handlePageChange(currentPageNumber);
  // }, [currentPageNumber]);

  return (
    <div className={css.tableWrapper}>
      {rowData.length ? (
        <>
          {is_finance_team_member && (
            <>
              {selectedPayments?.length > 0 ? (
                <Button variant="text" onClick={actionButtonHandler}>
                  {is_finance_team_member ? `Make Payment` : `Send Alert`} for{' '}
                  {selectedPayments?.length}{' '}
                  {selectedPayments?.length == 1 ? `Payment` : `Payments`}
                </Button>
              ) : (
                <Button variant="text">
                  {is_finance_team_member ? `Make Payment` : `Send Alert`} for all Payments
                </Button>
              )}
            </>
          )}

          <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
          {/* <PaginationScroller
            variant="text"
            defaultPage={1}
            count={count}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={validatePageChange}
          /> */}
        </>
      ) : (
        <ListPlaceholder
          title="No Payment Initiated"
          supportingText="You have not initiated any payment yet."
        />
      )}
    </div>
  );
};

export default PaymentRequested;
