import Checkbox from '@components/base/checkbox/checkbox';
import { DataGrid, ListPlaceholder } from '@components/common';
import { useEffect, useMemo } from 'react';
import { CellProps } from 'react-table';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { getFormattedDate } from '@helpers/utils';

const PaymentDone = (props: any) => {
  const { data } = props;
  const [columnData, rowData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Payment ID',
        accessor: 'payment_id_display'
      },
      {
        Header: 'Order ID',
        accessor: 'readable_order_id'
      },
      {
        Header: 'Supplier',
        accessor: 'supplier'
      },
      {
        Header: 'Total Amount',
        accessor: 'total_invoice_amount'
      },
      {
        Header: 'Amount Paid',
        accessor: 'total_payable_amount'
      },
      {
        Header: 'Invoice Details',
        accessor: 'invoice',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.invoice_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.invoice_date ?? '')}</Typography>
            </>
          );
        }
      },
      {
        Header: 'Bank Payment Info',
        accessor: 'bank_info',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <>
              <Typography variant="p">{value?.reference_number}</Typography>
              <Typography variant="p">{getFormattedDate(value?.payment_date ?? '')}</Typography>
            </>
          );
        }
      }
    ];

    const row: Array<any> = data?.length
      ? data?.map((item: any) => ({
          payment_id_display: item?.payment_id_display ?? '-',
          readable_order_id: item?.order_readable_ids?.length
            ? item?.order_readable_ids?.join(', ')
            : item?.order_readable_ids ?? '-',
          supplier: item?.supplier_name,
          total_invoice_amount: `${item.currency} ${item?.amount_to_pay}`,
          total_payable_amount: `${item.currency} ${item?.total_payable_amount}`,
          invoice: item,
          bank_info: item?.payment_confirmation_details ?? null
        }))
      : [];

    return [column, row];
  }, [data]);

  return (
    <div className={css.tableWrapper}>
      {rowData.length ? (
        <DataGrid columns={columnData} data={rowData} tableStyle={{ width: '100%' }} />
      ) : (
        <ListPlaceholder
          title="No Payment Completed"
          supportingText="You have not completed any payment yet."
        />
      )}
    </div>
  );
};

export default PaymentDone;
