import css from './index.module.scss';
import { Typography, ToolTip, Chip, ToolTipProps } from '@components/base';

interface multiplePlaceholder {
  names: string[];
  label?: string;
  placement?: ToolTipProps['placement'];
  onClick?: () => void;
}

const MultiplePlaceholder = (props: multiplePlaceholder) => {
  const { names, label = 'Multiple', placement = 'right', onClick } = props;
  if (names && names.length > 0) {
    if (names.length > 1) {
      return (
        <ToolTip title={names.join(', ')} placement={placement}>
          <div className={css.orderNameChip} onClick={onClick}>
            <Chip label={label} clickable={true} />
          </div>
        </ToolTip>
      );
    } else {
      return <Typography variant="p">{names[0] ?? '-'}</Typography>;
    }
  } else {
    return <Typography>-</Typography>;
  }
};

export default MultiplePlaceholder;
